.suggWrapper {
    background-color: #f0f0f0;
    margin: 16px;
    padding: 4px 8px;
    border-radius: 8px;
    width: 65%;
    align-self: center;
    min-width: 284px;
}

.suggHeader {
    font-weight: bold;
}

.suggList {

}

.suggItem {
    border-left: #1596C0 solid 2px;
    margin: 8px 0;
    padding-left: 4px;
}
