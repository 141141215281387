.bar {
    display: flex;
}
.avatar {
    padding: 16px;
}
.avatar img, .avatar div {
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    border-radius: 50%;
    width: 44px;
    height: 44px;
}
.heading {
    flex: 1;
    display: grid;
    place-content: center;
    font-weight: bold;
    font-size: 14pt;
}
.closeButton {
    width: 44px;
    height: 44px;
    display: grid;
    place-content: center;
    margin: 16px;
}
