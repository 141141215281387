.title, .basicText, .hint, .subTitle {
    overflow: hidden;
    word-break: break-word;
    user-select: none;
}
.title {
    font-weight: bolder;
    font-size: 1.1rem;
    margin-bottom: 4px;
}

.subTitle {
    font-weight: bolder;
    font-size: 1rem;
    margin-bottom: 4px;
}

.basicText {
    font-size: 1rem;
    margin-bottom: 4px;
}

.hint {
    font-size: .8rem;
    margin-bottom: 4px;
}
