.modal{
  width: 570px;
}

.red{
  color:#f85149
}

.bold{
  font-weight: 700;
}

.modalHeaderContent{
  padding:0;
  padding:16px 19px 0px 50px; 
  flex-direction: column;
}

.modalHeaderWrapper{
  padding:0;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.modalTitle{
  padding:0;
  line-height:120%;
  font-weight:bold;
  font-weight: 700;
  padding-top:32px;
}
.modalSubHead{
  display:block;
  margin-top:34px;
  font-weight: 700;
}

.modalBody{
  border:none;
  padding:0px 66px 0px 66px;
  display: flex;
  flex-direction: column;
  gap:13px;
}
.modalBodyList{
  margin:0;
  padding:0;
  list-style: none;
  text-align: left;
  align-items: left;
  gap:10px;
  padding-right: 90px;
  display: flex;
  flex-direction: column;
  gap:11px;
}
.modalBodyItem{
  display:flex;
  align-items: center;
}
.modalBodyItemTitle{
  margin:0;
  margin-left:20px;
}

.modalParagraph{
  margin:0;
  text-align: left;
}
.modalCancelWrapper{
  display: flex;
  justify-content: center;
}
.modalFooterActions{
  width: 100%;
  gap:8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.modalFooter{
  border:none;
  padding:0px 66px 32px 66px; 
  margin-top:28px; 
  display:flex;
  flex-direction:column; 
  align-items:center
}
.footerTitle{
  width: 100%;
  margin-top:20px;
  text-align: left;
  font-weight: 700;
  opacity: 0.7;
  display: block;
}

.modalCancelButton{
  &:hover{
    -webkit-box-shadow: inset 0px -1px 36px -14px rgba(0, 0, 0, 0);
    -moz-box-shadow: inset 0px -1px 36px -14px rgba(0, 0, 0, 0);
    box-shadow: inset 0px -1px 36px -14px rgba(0, 0, 0, 0);
  }
}


@media (max-width: 828px) {
  .modal{
    width: 100%;
    font-size:0.8rem
  }

  .modalHeaderContent{
    padding:0;
    padding:16px 19px 0px 50px; 
    flex-direction: column;
  }

  .modalBody{
    border:none;
    padding:0px 36px 0px 36px;
    display: flex;
    flex-direction: column;
    gap:13px;
  }

  .modalFooter{
    border:none;
    padding:0px 36px 32px 36px; 
    margin-top:28px; 
    display:flex;
    flex-direction:column; 
    align-items:center
  }
}


@media (max-width: 528px) {
  .modal{
    width: 100%;
    font-size:0.8rem
  }

  .modalHeaderContent{
    padding:0;
    padding:16px 10px 0px 41px; 
    flex-direction: column;
  }

  .modalBody{
    border:none;
    padding:0px 16px 0px 36px;
    display: flex;
    flex-direction: column;
    gap:13px;
  }

  .modalFooter{
    border:none;
    padding:0px 36px 32px 36px; 
    margin-top:28px; 
    display:flex;
    flex-direction:column; 
    align-items:center
  }
  .modalFooterActions{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}