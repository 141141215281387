.input {
    outline: none;
    border: none;
    border-bottom: solid 2px #888;
    margin-top: -2px;
    box-sizing: border-box;
    transition-duration: .3s;
    background-color: transparent;
    padding-left: 0 !important;
}

.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    border-bottom: solid 2px #888;
    margin-top: -2px;
    box-sizing: border-box;
    transition-duration: .3s;
    background-color: transparent;
    padding-left: 0 !important;
}

.input:focus::placeholder {
    color: transparent;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.input:disabled {
    color: black
}


.title {
    transition-duration: .3s;
    font-size: .7rem;
    font-weight: bold;
    user-select: none;
    display: flex;
}

.requiredStar {
    color: red;
    margin-left: 4px;
    transition-duration: .3s;
}
