.wrapper {
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 4px;
}

.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}
