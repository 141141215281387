.container {
    background: white;
    border-right: 1px solid rgba(217, 217, 217, 0.51);
    flex-shrink: 0;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.userContainer {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userContainer::before {
    content: '';
    position: absolute;
    left: 8px;
    width: 1px;
    height: 36px;
    background: #E7E7E8;
}

.user {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    column-gap: 12px;
    padding: 0 21px;
}

.userInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userRow {
    flex-wrap: nowrap;
    align-items: center;
}

.userNameText {
    display: flex;
    flex-flow: column;
    flex-shrink: 1;
    row-gap: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
}

.subtitle {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    color: #83878B;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.listItemContainer {
    width: 100%;
    user-select: none;
    color: #83878B;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E7E7E8;
    transition: 0.07s;
}

.listItemContainer.active {
    color: #b94189;
    background-color: #E7E7E8;
    border-left: 3px solid #b94189;
}

.listItemContainer:hover {
    background-color: rgba(236, 244, 247, 0.44);
}

.listItemContainer.active:hover {
    background-color: #E7E7E8;
}

.navLink {
    width: 100%;
}

.navLink:hover {
    text-decoration: none;
}

.navLink:first-of-type {
    margin: 28px 0 0;
    border-top: 1px solid #E7E7E8;
}

.titleContainer {
    position: relative;
    top: 1px;
    padding-left: 10px;
}

.logoutContainer {
    font-weight: 300;
    color: #bababa;
    font-size: 13px;
    text-align: right;
}

.settingsIconContainer {
    flex-grow: 0;
    display: flex;
    align-items: center;
}

.settingsIcon {
    transition: ease-in-out 0.15s;
    cursor: pointer;
    color: #554590;
}

.settingsIcon:hover {
    transform: rotate(30deg);
}

.avatarContainer {
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    margin-left: 10px;
}

.avatarImage {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    opacity: 1;
    border-radius: 4px;
    transition: 0.15s ease-in-out;
}

.avatarImage:hover {
    opacity: 0;
}
  
.avatarImage :global(span) {
  color: #B11873;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.copyrightContainer {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 4px;
    flex: 1;
}

.copyrightText {
    color: #bababa;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
}

.avatarPlaceholder {
    top: 0;
    left: 0;
}
.languageSelector {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.dropdownButton{
    background: #B11873;
    border: none;
    padding: 10px;

    &:focus{
        box-shadow: none;
    }
    &:hover{
        background: #B11873;
    }
 }


@media only screen and (max-width: 1320px) {
    .latestDocuments {
        display: none;
    }
    .titleContainer {
        display: none;
    }
    .user {
        justify-content: flex-start;
    }

    .avatarContainer {
        margin: 7px;
        transition: 0.2s;
    }
    .container {
        width: 68px !important;
        position: absolute;
        background-color: white;
        z-index: 999;
        transition: 0.2s;
        height: calc(100vh - 60px);
    }
    .container.containerOpen {
        width: 246px !important;
        transition: 0.2s;
    }
    .container.containerOpen .userContainer .userInfoContainer {
        display: block;
    }
    .container.containerOpen div .listItemContainer .titleContainer {
        display: block;
        white-space: nowrap;
        overflow: hidden;
    }
    .container.containerOpen .userContainer .user .avatarContainer {
        display: block;
    }
    .container.containerOpen > .copyrightContainer {
        opacity: 1;
        animation-delay: 0.3s;
    }

    .copyrightContainer {
        min-height: 34px;
        flex: 0;
        white-space: nowrap;
        overflow: hidden;
        opacity: 0;
        transition: 0.3s;
        align-items: flex-start;
        margin-left: 25px;
    }

    .burgerContainer {
        margin-top: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 4px;
        flex: 1;
        padding-left: 22px;
        position: relative;
    }
    .bugerContent {
        height: 20px;
        position: absolute;
        bottom: 15px;
        width: 20px;
        cursor: pointer;
        z-index: 9999999999;
    }

    .burger {
        margin-bottom: 20px;
        cursor: pointer;

        > .burgetItem {
            position: relative;
            display: block;
            width: 20px;
            height: 2px;
            background: #676767;
            transition: all 0.2s ease-in-out;

            &:before,
            &:after {
                position: absolute;
                background: #676767;
                content: "";
                width: 20px;
                height: 2px;
                transition: all 0.2s ease-in-out;
            }

            &:before {
                top: -7px;
            }

            &:after {
                top: 7px;
            }
        }
    }

    .burger.containerOpen {
        span {
            background: transparent;

            &:before {
                transform: rotate(45deg) translate(4px, 6px);
            }

            &:after {
                transform: rotate(-45deg) translate(4px, -6px);
            }
        }
    }
}
