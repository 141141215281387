.wrapper {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    /*overflow: auto;*/
}

.hide {
    display: none;
}

.hat {
    flex: 0;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    align-items: center;
    background-color: #554590;
}

.logout {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}
.logoImage {
    margin-top: -10px;
}

.main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 80px);
    padding: 1rem 0 100px 0;
    flex: 1 0 auto;
}

.barContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.barLine {
    position: absolute;
    background-color: #eaeef1;
    height: 4px;
    margin-top: -2px;
    z-index: 0;
}

.barUnitContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.barUnitText {
    font-size: 0.8rem;
}

.barUnitRound {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeef1;
    border-radius: 100%;
}

.barUnitRound.complete {
    /*background-color: red;*/
}

.barUnitRound.current {
    border: 1.5px solid #38b54a;
    box-sizing: border-box;
}

.check {
    border: 0 solid #38b54a;
    border-left-width: 3px;
    border-bottom-width: 3px;
    transform: rotate(-48deg);
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    justify-content: center;
    /*width: 20rem;*/
}

.next,
.skip {
    display: flex;
    justify-content: center;
    /*flex-grow: 0;*/
    /*width: 50%;*/
    margin-top: 1rem;
    padding: 0 1rem;
    /*width: 15rem;*/
}

.button {
    width: 300px;
}

.areaHeader {
    padding-left: 1rem;
}

.areaCompany {
    padding-left: 1.5rem;
    padding-top: 0.8rem;
    border-radius: 0.2rem;
    /*margin-bottom: 2rem;*/
    border: 1px solid #eeeeee;
    background-color: #f8f8f8;
    width: 100%;
    height: 100px;
    resize: none;
    outline: none;
    font-size: 0.9rem;
}

.banking {
    /*height: 50px;*/
    /*padding-bottom: 0.5rem;*/
    margin-bottom: 0.4rem;
}

.areaBusiness {
    padding-left: 1rem;
    font-size: 0.9rem;
    padding-top: 0.6rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    border: 1px solid #dedede;
    width: 100%;
    height: 130px;
    resize: none;
    outline: none;
}

.areaBusiness.grey {
    border: 1px solid #eeeeee;
    background-color: #f8f8f8;
}

.areaBusiness.lastArea {
    margin-bottom: 0;
}

.areaHeader {
    font-weight: 700;
    font-size: 0.9rem;
    margin-bottom: 3px;
    text-align: left;
    padding-left: 1rem;
}

.header {
    justify-content: center;
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.subheader {
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 700;
}

.final {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*padding-top: 1rem;*/
    /*justify-content: center;*/
}

.final .email {
    font-weight: 700;
    /*padding: 0 0.5rem;*/
}

.final .message {
    font-size: 1.1rem;
    /*padding:*/
    /*margin-bottom: 1rem;*/
}

.final .start {
    /*font-size: 1.6rem;*/
    margin-top: 1.5rem;
    width: 300px;
}

.footer {
    position: absolute;
    bottom: 0;
    margin-top: 3rem;
}
.bussinesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 1077px) {
    .bussinesGrid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}
