.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 24px;
  border-radius: 2px;
}

.button:not(.button[disabled]) {
  background: #e8bad5;
  border: 1px solid #e8bad5;
}

.button:not(.button[disabled]) :global(svg > path) {
  stroke: #b11873;
}

.button[disabled] {
  background: #f3f3f4;
  border: 1px solid #e7e7e8;
}

.jump {
  font-size: 14px;
  line-height: 20px;
  padding-top: 2px;
}

.pagination :global(.rc-pagination) {
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.pagination :global(.rc-pagination ul),
.pagination :global(.rc-pagination ol) {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pagination :global(.rc-pagination-start) {
  justify-content: start;
}
.pagination :global(.rc-pagination-center) {
  justify-content: center;
}
.pagination :global(.rc-pagination-end) {
  justify-content: end;
}
.pagination :global(.rc-pagination::after) {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.pagination :global(.rc-pagination-total-text) {
  display: inline-flex;
  height: 20px;
  margin-right: 8px;
  line-height: 26px;
  vertical-align: middle;
}
.pagination :global(.rc-pagination-item) {
  display: inline-flex;
  height: 20px;
  min-width: 20px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  list-style: none;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.pagination :global(.rc-pagination-item a) {
  display: block;
  padding: 0 6px;
  color: #070f17;
  transition: none;
}
.pagination :global(.rc-pagination-item a:hover) {
  text-decoration: none;
}
.pagination :global(.rc-pagination-item:focus),
.pagination :global(.rc-pagination-item:hover) {
  border-color: #b11873;
  transition: all 0.3s;
}
.pagination :global(.rc-pagination-item:focus a),
.pagination :global(.rc-pagination-item:hover a) {
  color: #b11873;
}
.pagination :global(.rc-pagination-item-active) {
  font-weight: 500;
  border-color: #b11873;
  text-decoration: underline;
  text-decoration-color: #b11873;
  text-underline-offset: 2px;
}
.pagination :global(.rc-pagination-item-active a) {
  color: #b11873;
}
.pagination :global(.rc-pagination-item-active:focus),
.pagination :global(.rc-pagination-item-active:hover) {
  border-color: #b11873;
}
.pagination :global(.rc-pagination-item-active:focus a),
.pagination :global(.rc-pagination-item-active:hover a) {
  color: #b11873;
}
.pagination :global(.rc-pagination-jump-prev),
.pagination :global(.rc-pagination-jump-next) {
  outline: 0;
}
.pagination :global(.rc-pagination-jump-prev button),
.pagination :global(.rc-pagination-jump-next button) {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}
.pagination :global(.rc-pagination-jump-prev button:after),
.pagination :global(.rc-pagination-jump-next button:after) {
  display: block;
  content: '•••';
}
.pagination :global(.rc-pagination-prev),
.pagination :global(.rc-pagination-jump-prev),
.pagination :global(.rc-pagination-jump-next) {
  margin-right: 8px;
}
.pagination :global(.rc-pagination-prev),
.pagination :global(.rc-pagination-next),
.pagination :global(.rc-pagination-jump-prev),
.pagination :global(.rc-pagination-jump-next) {
  display: inline-block;
  min-width: 20px;
  height: 24px;
  color: #070f17;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.pagination :global(.rc-pagination-prev),
.pagination :global(.rc-pagination-next) {
  outline: 0;
}
.pagination :global(.rc-pagination-prev button),
.pagination :global(.rc-pagination-next button) {
  color: #070f17;
  cursor: pointer;
  user-select: none;
}
.pagination :global(.rc-pagination-prev:hover button),
.pagination :global(.rc-pagination-next:hover button) {
  border-color: #b11873;
}
.pagination :global(.rc-pagination-prev .rc-pagination-item-link),
.pagination :global(.rc-pagination-next .rc-pagination-item-link) {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.pagination :global(.rc-pagination-prev:focus .rc-pagination-item-link),
.pagination :global(.rc-pagination-next:focus .rc-pagination-item-link),
.pagination :global(.rc-pagination-prev:hover .rc-pagination-item-link),
.pagination :global(.rc-pagination-next:hover .rc-pagination-item-link) {
  color: #b11873;
  border-color: #b11873;
}
.pagination :global(.rc-pagination-prev button:after) {
  content: '‹';
  display: block;
}
.pagination :global(.rc-pagination-next button:after) {
  content: '›';
  display: block;
}
.pagination :global(.rc-pagination-disabled),
.pagination :global(.rc-pagination-disabled:hover),
.pagination :global(.rc-pagination-disabled:focus) {
  cursor: not-allowed;
}
.pagination :global(.rc-pagination-disabled .rc-pagination-item-link),
.pagination :global(.rc-pagination-disabled:hover .rc-pagination-item-link),
.pagination :global(.rc-pagination-disabled:focus .rc-pagination-item-link) {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.pagination :global(.rc-pagination-slash) {
  margin: 0 10px 0 12px;
}
.pagination :global(.rc-pagination-options) {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .pagination :global(.rc-pagination-options *::-ms-backdrop),
  .pagination :global(.rc-pagination-options) {
    vertical-align: top;
  }
}
.pagination :global(.rc-pagination-options-size-changer.rc-select) {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.pagination :global(.rc-pagination-options-quick-jumper) {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
}
.pagination :global(.rc-pagination-options-quick-jumper input) {
  width: 50px;
  margin: 0 8px;
}
.pagination :global(.rc-pagination-simple .rc-pagination-prev),
.pagination :global(.rc-pagination-simple .rc-pagination-next) {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.pagination
  :global(.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link),
.pagination
  :global(.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link) {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.pagination
  :global(
    .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after
  ),
.pagination
  :global(
    .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after
  ) {
  height: 24px;
  line-height: 24px;
}
.pagination :global(.rc-pagination-simple .rc-pagination-simple-pager) {
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: 8px;
}
.pagination :global(.rc-pagination-simple .rc-pagination-simple-pager input) {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.pagination
  :global(.rc-pagination-simple .rc-pagination-simple-pager input:hover) {
  border-color: #1890ff;
}
.pagination :global(.rc-pagination.rc-pagination-disabled) {
  cursor: not-allowed;
}
.pagination :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item) {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.pagination
  :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item a) {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.pagination
  :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item-active) {
  background: #dbdbdb;
  border-color: transparent;
}
.pagination
  :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a) {
  color: #fff;
}
.pagination
  :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item-link) {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.pagination
  :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon) {
  opacity: 0;
}
.pagination
  :global(.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis) {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .pagination :global(.rc-pagination-item-after-jump-prev),
  .pagination :global(.rc-pagination-item-before-jump-next) {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .pagination :global(.rc-pagination-options) {
    display: none;
  }
}
