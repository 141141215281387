.title {
    font-weight: 700;
    font-size: 0.8rem;
    color: #656565;
}

.form {
    padding: 0 1rem;
    width: 700px;
}

.tableHeader {
    /*color: red;*/
    height: 50px;
    text-align: left;
}

.tableHeader * {
    white-space: pre-wrap !important;
}