.heading {
  color: #070f17;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 6px;
}

.line {
  width: 100%;
  height: 1px;
  background: #cdcfd1;
}
