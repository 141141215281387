.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
}

.wrapper :global(div > div:last-of-type) {
  display: none;
}

.extra {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8bad5;
  color: #b11873;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
}
