/* Customize the label (the container) */
.container {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    /*padding-left: 35px;*/
    cursor: pointer;
    /*font-size: 22px;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    /*height: 0;*/
    /*width: 0;*/
}

/* Create a custom checkbox */
.checkmark {
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #eee;
}

/*!* On mouse-over, add a grey background color *!*/
/*.container:hover input ~ .checkmark {*/
/*    background-color: #ccc;*/
/*}*/

/*!* When the checkbox is checked, add a blue background *!*/
/*.container input:checked ~ .checkmark {*/
/*    background-color: #2196F3;*/
/*}*/

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 6px;
    top: 1px;
    width: 8px;
    height: 14px;
    border: solid #868686;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(38deg);
    -ms-transform: rotate(38deg);
    transform: rotate(38deg);
}