.workerCard {
    padding: 12px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #ccc;
    user-select: none;
}
.workerHeader {
    display: flex;
    gap: 12px;
}

.workerControllers {
    color: black;
    cursor: pointer;
    transition-duration: 0.3s;
    opacity: 0;
    position: absolute;
    background-color: white;
    margin-top: -3px;
    margin-left: -2px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workerControllers:hover {
    opacity: 1;
}

.workerNameAndRole {
    display: flex;
    gap: 8px;
    align-items: center;
}

.workerEmail {
    font-size: 10pt;
    color: #888888;
}

.workerProfilePicture,
.smallAvatar {
    display: flex;
    border-radius: 8px;
}

.managerDot {
    width: 10px;
    height: 10px;
    background-color: #f87c18;
    border: solid 1px white;
    border-radius: 5px;
    align-self: flex-end;
    margin-left: -10px;
}
.smallDot {
    margin-left: -10px;
    margin-bottom: -2px;
    height: 10px;
    width: 10px;
}
.workerProfilePicture img {
    width: 44px;
    height: 44px;
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    border-radius: 50%;
}

.smallAvatar img {
    width: 32px;
    height: 32px;
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    border-radius: 8px;
}
.workerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.workerName {
    font-weight: bold;
    font-size: 12pt;
}

.workerRole {
    font-size: 10pt;
}

.workerContent {
    padding-top: 12px;
}

.smallWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.smallName {
    font-size: 11pt;
}
