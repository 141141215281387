.wrapper {
  display: flex;
  flex-flow: column;
  row-gap: 16px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 10px;
}

.layout {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.column {
  flex: 1 1;
}

.form {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
}

.form > :global(div) {
  flex: 49%;
}

.form > :global(div:nth-of-type(2)),
.form > :global(div:nth-of-type(3)) {
  flex: 24%;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 12px;
}

.buttons > :global(div) {
  width: 155px !important;
  height: auto !important;
}

.button {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  padding: 8px 26px 8px 28px !important;
}
