.header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.header i {
    font-size: 16px;
}
.dateToday {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #070f17;

    padding: 12px 20px 0 0;
}

.mainRow {
    font-family: Roboto;
    font-style: normal;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
    padding: 10px 20px;
}

.overview {
    grid-area: overview;
    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-radius: 4px;
}

.overviewHeader {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #070f17;
}

.overviewHeader::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #cdcfd1;
    margin-top: 5px;
    margin-bottom: 10px;
}

.overviewContent {
    display: flex;
    align-items: center;
}

.overviewItem {
    display: flex;
    align-items: center;
}
.overviewItem:not(:last-child) {
    margin-right: 20px;
}

.overviewIcon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.overviewIcon i {
    font-size: 8px;
}

.overviewType {
    margin-right: 20px;
    color: #83878b;
    white-space: nowrap;
}

.overviewQuatity {
    color: #070f17;
}

.overviewQuatity,
.overviewType {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

/* =========================================== */

.latestDocuments {
    grid-area: lastDocuments;

    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-radius: 4px;
}

.document {
    display: grid;
    grid-template-areas: "status  title date icons";
    grid-template-columns: 1fr 3fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    margin-bottom: 10px;
}

.documentStatus {
    grid-area: status;

    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 8px;
    border-radius: 4px;
    min-width: 180px;
    display: flex;
}

.documentTitle {
    grid-area: title;

    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #070f17;
}

.documentDate {
    grid-area: date;

    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #83878b;
}

.documentIcon {
    grid-area: icons;
}
.documentIcon img {
    width: 16px;
    height: 16px;
}
.latestDocumentsHeader,
.departmantsHeader {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #070f17;
    margin-bottom: 17px;
}

.latestDocumentsHeader::after,
.departmantsHeader::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #cdcfd1;
    bottom: -6px;
}

/* =========================================== */

.departments {
    grid-area: departments;

    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-radius: 4px;
}

.departmentList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}
.departmantsHeader i,
.latestDocumentsHeader i {
    font-size: 16px;
    margin: 0;
}
.departmantsHeader div,
.latestDocumentsHeader div {
    padding: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

/* ======================== */

.inviteOverview{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
}

.mainInviteRow {
    display: flex;
    padding: 20px;
    column-gap: 20px;
    overflow: auto;
    white-space: nowrap;
    max-width: 1200px;
}

.inviteCard {
    display: flex;
    justify-content: space-between;
    width: 420px;
    height: 150px;
    column-gap: 20px;
    padding: 10px;
    border-radius: 10px;
    background: #F8F8FF;
    color: #070f17;
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 15px;
}

.inviteCardInfo {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
}

.inviteCardDate {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.inviteCardButtons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.inviteCardFooter {
    display: flex;
    width: inherit;
    justify-content: space-between;
}

.noInvite {
    display: flex;
    height: 150px;
    align-items: center;
    justify-content: center;
}