.wrapper {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  margin: 0;
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 0 22px;
}

.checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1.5px solid #cdcfd1;
  border-radius: 15px;
  transition: all 240ms ease;
}

.wrapper:hover .checkbox ~ .checkmark {
  border-color: #b11873;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.wrapper .checkbox:checked ~ .checkmark {
  /* background: #b11873; */
  border-color: #b11873;
}

.wrapper .checkbox:checked ~ .checkmark:after {
  display: flex;
}

.wrapper .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  background: #b11873;
  border-radius: 15px;
  animation: appear 300ms;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
