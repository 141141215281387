.tox-sidebar-wrap {
    width: 210mm !important;
    align-self: center;
}

.tox-editor-container {
    background-color: rgba(200,200,200,.3) !important;
    backdrop-filter: blur(15px) !important;
    z-index: 99 !important;
}
.tox-dialog-wrap {
    z-index: 20000 !important;
}

.tox-tinymce {
    border-radius: 6px !important;
}
