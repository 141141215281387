.flowsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.flowsCardHeader {
    display: flex;
    align-items: center;
    gap: 8px
}

.department {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.departmentName {
    cursor: pointer;
    padding-left: 16px;
    border-left: solid 2px transparent;

}

.departmentName:hover {
    border-left: solid 2px #33ACC9;
}

.addFlow {
    font-weight: lighter;
    cursor: pointer;
    padding-left: 16px;
    border-left: solid 2px transparent;
}


.modalRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px
}

.deleteButton {
    opacity: 0;
    transition-duration: .2s;
}

.department:hover .deleteButton {
    opacity: 1;
}

.hint {
    color: #888
}

.departmentWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 24px;
}

.departmentTopContainerWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    background: #FFFFFF;
    border: 1px solid rgba(7, 15, 23, 0.1);
    padding: 15px 20px;
    border-radius: 4px;
}

.flowsControls {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.departmentTitle {
    display: flex;
}

.departmentStaffSize {
    background: #BCE2EC;
    border-radius: 4px;
    height: fit-content;
    padding: 4px 8px;
    color: #1E9DBD;
    font-size: 16px;
    font-weight: 900;
    margin-left: 10px;
}

.departmentTableAlignment {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr;
}

.departmentTableCell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
}

.departmentTableHeader {
    background-color: #FFFFFF;
    padding: 10px 0 10px 4px;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 5px;
}

.departmentTableColumn{
    cursor: pointer;
    padding: 10px 0;
    background-color: #FFFFFF;
    border: 1px solid #E7E7E8;
    border-left: 5px solid #B11873;
}

.departmentTableColumn:hover {
    border-left: 3px solid #B11873;
    transition: 200ms;
}

.cellAvatar {
    display: flex;
    justify-content: center;
}

.columnCellButtons {
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.controlButton {
    padding: 5px 10px;
    border-radius: 4px;
    border: none;
    outline: none !important;
}

.inviteButtonContainer {
    max-width: 140px;
    justify-content: flex-start !important;
}

.tableControls {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.inviteButtonContent {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: center;
}

.departmentControllers {
    display: flex;
    height: fit-content;
    column-gap: 10px;
}

.departmentHeader {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.departmentNameInfo {
    display: flex;
    column-gap: 5px;
}

.departmentContainer {
    display: flex;
    flex-direction: column;
}

.departmentMain {
    display: flex;
    justify-content: center;
    padding: 1rem 0 1rem 0;
}

.departmentChartContainer {
    width: 400px; 
    height: 400px;
}