.wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.percent {
  color: #070f17 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  white-space: pre;
}

.bar {
  display: flex;
  height: 10px;
  border: 1.5px solid #b5b7ba;
  border-radius: 2px;
}

.progress {
  position: relative;
  width: 0%;
  height: 100%;
  background: #5ed615;
}

.progress::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  background: #5ed615;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 1px solid #55c215;
  border-radius: 2px;
}
