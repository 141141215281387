.button {
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    min-height: 32px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.transparent {
    background-color: transparent;
    color: #308c67;
}

.button:active, .button:focus{
    outline: none;
    border: none;
}

.button:hover, .button:disabled {
    opacity: .7;
}


.roundButton {
    background-color: #e5eeff;
    border-radius: 12px;
    padding: 0 8px ;
    min-height: 24px;
    min-width: 24px;
    font-weight: normal !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: black;
}

.disabled {
    opacity: .5;
}

.roundButton i {
    font-size: 12px;
}

