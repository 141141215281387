.leftButton {
    margin: 4px 8px;
    width: fit-content;
}

.rightButton {
    margin: 4px 8px;
    width: fit-content;
}

.buttonsContainer {
    margin: 0.7rem 0;
    display: flex;
    justify-content: flex-start;
    width: fit-content;

}
