.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 2rem 0 0 4rem;
    width: 300px;
    row-gap: 1rem;
}

.howTo {
    padding: 20px 60px;
    overflow-y: auto;
    height: calc(100vh - 60px);
}

.howTo img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
}


@media (max-width: 1077px) {
    .howTo {
        padding: 20px 20px;
        word-break: break-word;
        font-size: 0.875rem;
    }
    
}