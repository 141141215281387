.wrapper{
  background-color: #DCE0E4;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}
.btnWrapper{
  padding: 14px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #E7E7E8;;
}
.btnWrapper button {
  display: inline;
  width: auto;
}

.btnWrapper div{
  width: max-content !important ;
  display: inline-block;
}

.btnWrapper div:not(:last-child){
  margin-right: 14px;
}

.imageContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}
.textWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageContainer img {
  margin-top: 100px;
  margin-bottom: 35px;
  max-width: 350px;
  height: auto;
  object-fit: cover;
  object-position: center;
}
.resendDescription {
  font-size: 14px;
  line-height: 20px;
}
.resendButton {
  max-width: 210px;
  margin: 5px auto 15px;
}
.textNormal{
  font-size: 14px;
  line-height: 20px;
  color: #393F45;
}
.textBold{
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-transform: uppercase;
  color: #070F17;
}
.icon{
  stroke: #070F17;
  width: 40px;
  height: 40px;
}