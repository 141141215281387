.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: rgba(0,0,0,.42);
    z-index: 40000;
}
.container {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.searchWrapper {
    height: 100%;
}

.entitiesSection {

}

.loadingWrapper {
    display: grid;
    place-items: center;
    height: 100%;
    min-height: 300px;
    min-width: 600px;
}

.sectionName {
    font-weight: 500;
    padding: 0 8px;
}
.entitiesList {
    display: flex;
    flex-wrap: wrap;
}

.listWrapper {

}
.docList {
    max-height: 480px;
    overflow-y: auto;
}

.entityWrapper {
    background-color: #f5f5f5;
    border-radius: 8px;
    width: fit-content;
    margin: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 8px;
    text-align: center;
    flex: 1 1 auto;
    cursor: pointer;
    transition-duration: .2s;
}
.entityWrapper:hover {
    -webkit-box-shadow: inset 0px 2px 19px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 2px 19px -12px rgba(0,0,0,0.75);
    box-shadow: inset 0px 2px 19px -12px rgba(0,0,0,0.75);
}

.entityName {
    font-weight: 500;
}
.documentBar {
    display: grid;
    grid-template: 1fr/ 3fr 1fr 1fr 1fr;
    cursor: pointer;
}
.templateBar {
    display: grid;
    grid-template: 1fr/ 1fr 3fr 1fr 1fr;
    min-height: 30px;
    padding: 0 0 0 10px;
    cursor: pointer;
}
.customerBar {
    display: grid;
    grid-template: 1fr/ 1fr 2fr 3fr 1fr 1fr;
    min-height: 30px;
    padding: 0 0 0 10px;
    cursor: pointer;
}
.partnerBar {
    display: grid;
    grid-template: 1fr/ 1fr 2fr 3fr 1fr;
    min-height: 30px;
    padding: 0 0 0 10px;
    cursor: pointer;
}
.serviceBar {
    display: grid;
    grid-template: 1fr/ 1fr 3fr 1fr 1fr;
    min-height: 30px;
    padding: 0 0 0 10px;
    cursor: pointer;
}
.productBar {
    display: grid;
    grid-template: 1fr/ 1fr 3fr 1fr 1fr;
    min-height: 30px;
    padding: 0 0 0 10px;
    cursor: pointer;
}
.templateBar div, .customerBar div, .partnerBar div, .serviceBar div, .productBar div {
    display: flex;
    align-items: center;
}
.documentWrapper {
    background-color: #fff;
    border: 2px solid #dfdfdf;
    border-radius: 8px;
    margin: 8px;
    width: 600px;
    transition-duration: .3s;
}
.legendWrapper {
    background-color: #fff;
    border: solid 2px transparent;
    width: 600px;
    margin-left: 8px;
    color: #777;
}
.documentLegendBar {
    display: grid;
    grid-template: 1fr/ 3fr 1fr 1fr 1fr;
    cursor: pointer;
}
.templateLegendBar {
    display: grid;
    grid-template: 1fr/ 1.1fr 2.9fr 1fr 1fr;
    cursor: pointer;
}
.customerLegendBar {
    display: grid;
    grid-template: 1fr/ 1.1fr 1.9fr 3fr 1fr 1fr;
    cursor: pointer;
}
.partnerLegendBar {
    display: grid;
    grid-template: 1fr/ 1.1fr 1.9fr 3fr 1fr;
    cursor: pointer;
}
.serviceLegendBar {
    display: grid;
    grid-template: 1fr/ 1.1fr 2.9fr 1fr 1fr;
    cursor: pointer;
}
.productLegendBar {
    display: grid;
    grid-template: 1fr/ 1.1fr 2.9fr 1fr 1fr;
    cursor: pointer;
}
.legendItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0 0 0 5px;
}
.legendItem i {
    display: grid;
    place-items: center;
    color: #F87C18;
    margin-right: 8px;
}
.legendName {
    align-items: center;
    justify-content: start;
    padding-left: 8px;
}
.documentName {
    font-size: 16px;
    padding: 4px 8px;
}

.documentDate {
    display: grid;
    place-items: center;
    border-left: #ececec 2px solid;
}
.documentStatus {
    display: grid;
    place-items: center;
    border-left: #ececec 2px solid;
    text-align: center;
}

.documentButton {
    margin-right: 0px !important;
    border-radius: 0px 6px 0px 0px !important;
}

.documentButtonExpanded {
    margin-right: 0px !important;
    border-radius: 0px 6px 6px 0px !important;
}

.searchTitle {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
}

.closeButton {
    cursor: pointer;
    margin-left: 16px;
}
