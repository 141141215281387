.formContainer {
    padding: 0 1rem;
}

.form {
    width: 700px;
}

.profileAddress {
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.billingAction {
    min-width: 860px;
}

.billingInvoiceLink {
    padding: 4px;
    cursor: pointer;
}


@media (max-width: 797px) {
    .form {
        width: 100%;
    }
}

@media (max-width: 648px) {
    .billingAction{
        display: flex;
        flex-direction: column;
    }
}


.modal {
    width: 530px;
}

.bold {
    font-weight: 700;
}

.modalHeaderContent {
    padding: 0;
    padding: 16px 19px 0px 50px;
    flex-direction: column;
}

.modalHeaderWrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalTitle {
    padding: 0;
    line-height: 120%;
    font-weight: bold;
    padding-top: 32px;
    width: 80%;
}

.modalBody {
    border: none;
    padding: 0px 66px 0px 66px;
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.modalParagraph {
    margin: 0;
    text-align: left;
}

.modalCancelWrapper {
    display: flex;
    justify-content: center;
}

.modalFooterActions {
    width: 100%;
    gap: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
}

.modalFooter {
    border: none;
    padding: 0px 66px 32px 66px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.modalListTitle{
    font-weight: 700;
    color: #0D0D0D;
    text-align: left;
    margin-top:15px;
}

.modalBodyList {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap:2px;

}
.modalBodyItem{
    display:flex;
    align-items: flex-start;
  }
.modalBodyItemTitle{
    margin:0;
    margin-left:20px;
}
  

.modalFooterTitle{
    margin:0;
    padding:0;
    text-align: left;
    color: #0D0D0D;
    font-weight: 700;
    margin-bottom: 30px;
}


@media (max-width: 797px) {
    .modal{
      width: 100%;
      max-width: 530px;
      margin:0 auto;
    }
}

.avatarImage {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    object-fit: cover; 
    object-position: center; 
    opacity: 1;
    transition: 0.15s ease-in-out;
}

.avatarImage:hover {
    opacity: 0;
}
.avatarPlaceholder {
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0, 0.3);
}

.tooltipBtn {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #83878B;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 0.25rem;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }
  