.modalWrapper {
    padding: 16px;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}

.modalTitle {
    font-weight: bold;
}

.modalCloseButton {
    cursor: pointer;
}

.modalContent {

}
