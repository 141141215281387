.messageContainer {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.list {
    flex: 1;
    overflow-y: scroll;
    position: relative;
}

.messageIncoming {
    display: flex;
    background-color: #800080FF;
    padding: 12px;
    border-radius: 0 20px 20px 20px;
    flex-shrink: 1;
    width: fit-content;
    max-width: 86%;
}

.messageSent {
    background-color: #f1f1f1;
    padding: 12px;
    border-radius:  20px 20px 0 20px;
    width: fit-content;
    max-width: 86%;
}

.messageText {
    font-size: 12pt;
}

.previewWrapper {
    background-color: #f1f1f1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 12px;
    margin-top: 16px;
}

.previewText {
    margin-left: 8px;
}

.messageMarkdown {
    color: #ffffff;
    font-size: 12pt;
}

.messageMarkdown a {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.messageMarkdown p {
    margin-bottom: 0;
}

.messageMarkdown p + p {
    margin-top: 6px;
}

.messageMarkdown ul, .messageMarkdown ol {
    margin: 0;
    padding: 0 0 0 18px;
}

.messageMarkdown ul {
    list-style: disc;
}

.messageMarkdown h1,
.messageMarkdown h2,
.messageMarkdown h3,
.messageMarkdown h4,
.messageMarkdown h5,
.messageMarkdown h6 {
    font-size: 16px;
}

.messageMarkdown pre {
    color: inherit;
}
