.container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background-color: #ffffff;
  width: 30rem;
  border-radius: 1rem;
  padding: 20px 20px 10px;
}

.resetButton {
  margin-bottom: 1rem;
}

.fail {
  height: 0;
  transition: 250ms ease-in-out;
  text-align: center;
}

.failOpen {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  height: 2.5rem;
}

.formHolder {
  display: flex;
  flex-flow: column;
  row-gap: 8px;
  margin-top: -10px;
}

.formHolder > :global(.row) {
  display: flex;
  flex-flow: row;
  column-gap: 8px;
}
