.inputContainer {
    padding: 16px;
}

.inputWrapper {
    display: flex;
}

.input {
    background-color: transparent;
    outline: none;
    border: none;
    flex: 1;
    resize: none;
    align-self: center;
}

.textInputShape {
    display: flex;
    background-color: #f1f1f1;
    flex: 1;
    border-radius: 20px 4px 4px 20px;
    padding: 0 16px;
}

.buttonMiddle {
    display: flex;
    border-radius: 4px;
    background-color: #790079;
    padding: 16px 12px;
    justify-content: center;
    margin: 0 8px;
    align-self: flex-end;
}

.recordIndicator {
    position: absolute;
    margin-top: -76px;
    opacity: .5;
    transition-duration: .3s;
}

.buttonRight {
    display: flex;
    border-radius: 4px 20px 20px 4px;
    background-color: #1596C0;
    padding: 16px 16px 16px 12px;
    justify-content: center;
    align-self: flex-end;
}

.buttonDisabled {
    opacity: 0.8;
    cursor: wait;
}
