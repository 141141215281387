.sectionContainer {
    margin-bottom: 1.2rem;
    display: flex;
}

.leftContainer {
    font-weight: 700;
    font-size: 0.9rem;
}

.rightContainer {
    padding: 0 1rem;
    display: flex;
    align-items: center;
}

.sectionInputContainer {
    margin: 0;
    width: 70%;
}

.avatarsHolder {
    margin-top: -0.5rem;
    margin-left: -0.5rem;
}

.avatarCol {
    padding: 0.5rem;
}

.avatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0.6;
    transition: 0.15s ease-in-out;
}

.avatarContainerSelected {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(21,150,192,0.85);
    -moz-box-shadow: 0px 0px 5px 2px rgba(21,150,192,0.85);
    box-shadow: 0px 0px 5px 2px rgba(21,150,192,0.85);
    opacity: 1;
}

.avatarImage {

}


@media (max-width: 797px) {
    .rightContainer{
        margin-left:0;
        padding-left:0;
        margin-top:10px;
    }
    .sectionContainer{
        flex-direction: column;
    }
    .virtualAssistent{
        width: auto;
    }
}
