.header {
  margin: 12px 20px;
}

.line {
  width: 100%;
  height: 1px;
  background: #cdcfd1;
  margin: 8px 0 12px;
}

.tasks {
  display: flex;
  flex-flow: column;
  row-gap: 12px;
  padding: 0 20px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.pagination {
  padding: 0 20px !important;
  margin: 14px 0 30px !important;
}
