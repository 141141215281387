.overlay {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 6402;
    display: block;
}

.hideTutorialAvatar {
    display: none
}

.avatar {
    display: block;
    z-index: 6402;
    width: 150px;
    height: 150px;
    background: #fff;
    box-shadow: 0 0 10px rgb(177, 24, 115, 0.9);
    animation: avatar 1000ms alternate infinite;
}
  
@-webkit-keyframes avatar {
    0% {
        -webkit-box-shadow: 0 0 10px 10px rgb(177, 24, 115, 0.9);
    }
    70% {
        -webkit-box-shadow: 0 0 10px 20px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 10px 10px rgba(204,169,44, 0);
    }
}

@keyframes avatar {
    0% {
        -moz-box-shadow: 0 0 10px 10px rgb(177, 24, 115, 0.9);
        box-shadow: 0 0 10px 10px rgb(177, 24, 115, 0.9);
    }
    70% {
        -moz-box-shadow: 0 0 10px 10px rgba(204,169,44, 0);
        box-shadow: 0 0 10px 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 10px 10px rgba(204,169,44, 0);
        box-shadow: 0 0 10px 10px rgba(204,169,44, 0);
    }
}