.wrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
}

.centerLine {
    width: 100%;
    display: flex;
    justify-content: center;
    height: max-content;
}
.canvas {
    border-radius: .5rem;
}

.sideSpace {
    flex: 1;
    margin: 0 1rem;
}
.controller {
    padding: 1rem;
    background-color: #ececec;
    border-radius: 8px;
    width: calc(600px + 2rem);
    min-height: 450px;
    display: flex;
    flex-direction: column;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0 1rem;
}
.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}
.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3rem;
    flex: 1;
}

.button {
    width: 200px;
}

.placeholder {
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C9CBCA;
    font-size: 20pt;
    cursor: pointer;
    user-select: none;
}

.shareLinkContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex: 1;
    background-color: #ececec;
    border-radius: 3rem;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.code {
}

.codeArea {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.copyButton {
    display: flex;
    align-items: flex-end;
}

.columns {
    display: flex;
    flex-direction: row;
    flex: 1
}

.hint {
    font-weight: bold;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
    text-align: justify;
    align-items: flex-start;
    padding: .5rem 1rem;
    user-select: none;

}
.link {
    color: #1596C0;
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: .5rem;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
}
