.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.26);
  padding: 20px;
  transition: opacity 300ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.modalOpen {
  opacity: 1;
}

.modalShown {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  z-index: 9999;
}

.wrapper {
  width: 460px;
  background: white;
  border-radius: 4px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 10px;
  border-bottom: 1px solid #e7e7e8;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}

.closeButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton,
.closeButton button {
  width: 18px !important;
  height: 18px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.closeButton svg {
  width: 16px;
  height: 16px;
  color: #070f17 !important;
}

.form {
  display: flex;
  flex-flow: row wrap;
  padding: 14px 20px 20px;
  column-gap: 10px;
}

.form > :global(div) {
  flex: 48%;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 12px;
  width: 100%;
  padding: 0 20px 20px;
}

.footer > :global(div) {
  width: auto !important;
  height: auto !important;
}

.footer > :global(div:first-of-type) > button {
  color: #83878b !important;
}

.button {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  padding: 8px 26px 8px 28px !important;
}
