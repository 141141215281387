.wrapper {
    color: black;
    user-select: none;
    width: initial;
    word-break: normal;
}
.link {
    color: #1595bf;
    margin: 0 .5rem;
    text-decoration: none;
}

.link:hover {
    text-decoration: #1595bf underline;
}
