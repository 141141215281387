.main {
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 3rem 0 3rem 0;
    background-color: #F3F3F3;
}

.mainContent {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editor {
    position: relative;
    width: 100%;
    max-width: calc(21cm + 32px);
}

.buttonsContainer {
    display: flex;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    height: 40px;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
    column-gap: 10px;
}

.buttonsContainer.template {
    z-index: 2000;

}

.save {
    display: flex;
    height: 32px;
    grid-gap: 8px;
    color: #FFFFFF;
    background: #B11873;
    border-radius: 4px;
    padding: 8px;
}
.save:hover {
    transition: 0.4s ease-out;
    background: rgb(177, 24, 115, 0.7);
}

.dropdown {
    z-index: 1000;
    width: 10rem;
    margin-left: -7.5em;
    align-self: center;
    justify-self: center;
}

.save i {
    align-self: center;
}
.save div {
    font-size: 14px;
    align-self: center;
}

.saveDisabled {
    color: rgba(201, 201, 201, 1);
}

.fields {
    position: relative;
    width: 270px;
    border-left: 1px solid rgba(217, 217, 217, 0.51);
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 100%;
    z-index: 0;
    background-color: white;
}

.fieldsContent {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fields .header {
    font-size: 1.2rem;
    font-weight: 700;
    color: #070F17;
    margin: 0.7rem 0;

    text-align: center;
}

.field {
    flex-shrink: 0;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #070F17;
    width: 250px;
    margin-bottom: 0.5rem;
    border: 1px solid #070F17;
    cursor: grab;
}

.field .text {
    width: 100%;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: center;
    transition-duration: .2s;
    padding: .25rem;
}
.field .text:hover {
    transform: translateX(-.25rem);
}

.field .hint {
    font-size: 0.7rem;
    padding: 0 0.25rem 0.25rem 0.25rem;
    text-align: center;
}

.formContainer {
    width: 100%;
    max-width: calc(21cm + 32px);
    padding: 0;
    margin: 0;
}

.inputWrapper {
    padding: 0;
    margin-bottom: 0.6rem;
}

.inputWrapper input {
    padding: 0 1rem;
    height: 1.8rem;
    font-size: 0.9rem;
    border-radius: 0.2rem;
    width: 100%;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    transition: 0.1s;
}

.backButtonContainer {
    position: absolute;
    height: 5.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: -4rem;
    color: #C9CBCA;
    font-size: 2.5rem;
}


.inputErrorText span span {
    transition: 0.4s;
    color: #A80B12;
}

.inputErrorBorder {
    transition: 0.4s;
    border: 1px solid #A80B12;
}

.collapseHeader {
    flex-shrink: 0;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #E8BAD5;
    font-weight: bold;
    color: #070F17;
    width: 250px;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    padding: 0.25rem .75rem;
    cursor: pointer;
    transition-duration: .2s;
}
.opened:hover {
    transform: translateY(-2px);
}
.closed:hover {
    transform: translateY(2px);
}

.toolbar {
    position: relative;
    width: 270px;
    border-left: 1px solid rgba(217, 217, 217, 0.51);
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 100%;
    z-index: 0;
    background-color: white;
    padding-top: .5rem;
    display: flex;
    justify-content: center;
}

.btnWrapper {
    flex-shrink: 0;
    user-select: none;
    display: grid;
    grid-template-columns: 3rem 1fr;
    flex-direction: row;
    border-radius: 0.75rem;
    width: calc(100% - 8px);
    cursor: pointer;
    margin: 4px;
}


.btnLabel {
    font-size: 1rem;
    color: #2a1d16;
    font-weight: 700;
    transition-duration: .2s;
    padding: 1rem 0;
}

.btnIcon {
    align-self: center;
    justify-self: center;
}
.signContainer {
    background-color: rgba(0,0,0,0.42);
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}
.typeSelectionWrapper {
    background-color: #ececec;
    border-radius: 16px;
    width: calc(100% - 8px);
}

.publicPrivateSwitch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 150px;
}

.editorContainer {
    background: #FFFFFF;
    padding: 40px 20px 20px;
    border: 1px solid #E7E7E8;
    border-radius: 4px;
}

.inputContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    column-gap: 16px;
    margin: -35px 0 10px 0;
}

.inputContainer > :global(div) {
    flex: 1;
}
