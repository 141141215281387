.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.box {
    -webkit-box-shadow: 0px 0px 82px -11px rgba(0,0,0,0.45);
    -moz-box-shadow: 0px 0px 82px -11px rgba(0,0,0,0.45);
    box-shadow: 0px 0px 82px -11px rgba(0,0,0,0.45);
    background-color: #ffffff;
    width: 600px;
    border-radius: 1.5rem;
    padding: 1.5rem;
}

.header {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: 700;
    color: #554590;
    /*text-align: center;*/
}

.main {
    display: flex;
    justify-content: center;
}

.rightSideContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.uploadBox {
    cursor: pointer;
    outline: none;
    border-radius: 1.5rem;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 90px;*/
    width: 100%;
    flex: 1;
    border-style: dashed;
    border-color: rgba(204, 204, 204, 0.4);
    color: rgba(204, 204, 204, 0.9);
    text-align: center;
    border-width: 0.12rem;
    font-size: 1rem;
    transition: 0.15s ease-in-out;
}

.uploadBoxAccept {
    border-color: rgba(20, 192, 94, 0.85);
    color: rgba(20, 192, 94, 0.85);
}

.uploadBoxReject {
    border-color: rgba(170, 45, 13, 0.85);
    color: rgba(170, 45, 13, 0.85);
}

.avatarContainer {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1.5rem;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatarPlaceholder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0;
}

.avatarPlaceholderIcon {
    color: #D2D2D2;
}

.fileName {
    padding: 0.5rem 1rem;
}

.buttonsContainer {
    display: flex;
    margin-top: 0.7rem;
}

.rightButton {
    margin-left: 0.7rem;
}

.pdfPreview {
    width: 100%;
    height: 200px;
}

@media (max-width: 1077px) {
    .container{
        padding:20px
    }
    .main{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .buttonsContainer{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}