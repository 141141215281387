.container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 6rem;
    background-color: rgba(0,0,0,0.42);
    z-index: 1000;
    backdrop-filter: blur(4px);
}

.box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 600px;
    width: min-content;
    max-width: 90vw;
    overflow: hidden;
    min-height: 360px;
    height: auto;
    background-color: white;
    border-radius: 50px;
    padding: 50px;
}

.row {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.avatarRow {
    /*flex: 1.5;*/
}

.avatarContainer {
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    transition-timing-function: ease-in-out;
}

.avatarContainer.lighting {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(85,69,144,0.41);
    -moz-box-shadow: 0px 0px 0px 10px rgba(85,69,144,0.41);
    box-shadow: 0px 0px 0px 10px rgba(85,69,144,0.41);
}

.question {
    text-align: center;
    /*color: white;*/
    color: #554590;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8rem;
    max-height: 160px;
    overflow-y: auto;
    padding: 4px;
    max-width: 800px;
    word-break: break-word;
    /*margin-bottom: ;*/
    /*max-height: 3.6rem;*/
}

.listener {
    margin-top: 1rem;
}

.inputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.selectContainer {
    position: relative;
    max-width: 280px;
    flex: 1;
}

.submitUnderlier {
    position: absolute;
    background-color: #554590;
    border-radius: 100%;
    transform: scale(0.98);
}

.submit {
    position: absolute;
}

.submit .icon {
    font-size: 25px;
}

.microphone {
    margin-left: 15px;

}

.microphone .enabled {
    background-color: #1596C0;
    transition: background-color 0.1s ease-in-out;
}

.microphone .disabled {
    background-color: #C9CBCA;
    transition: background-color 0.1s ease-in-out;
}

.timer {
    position: absolute;
    top: 0;
    left: 0;
}

.finishButtonsHolder {
    display: flex;
    justify-content: space-between;
    width: 260px;
}

.singleInput {
    width: 100%;
    border: #1596C0 solid 2px;
    padding-left: 22px;
    padding-right: 50px;
    font-size: 1.1rem;
}

.previewButton {
    align-self: center;
}

.avatarPlaceholder {
    top: 0;
    left: 0;
}

.avatarImage {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 1;
    transition: 0.15s ease-in-out;
    -webkit-user-drag: none;
}

.modalBox {
    background-color: white;
    width: fit-content;
    max-height: 80vh;
    overflow-x: auto;
    box-sizing: border-box;
    border-radius: 1rem;
    -webkit-box-shadow: 0px 0px 82px -11px rgba(0,0,0,0.45);
    -moz-box-shadow: 0px 0px 82px -11px rgba(0,0,0,0.45);
    box-shadow: 0px 0px 82px -11px rgba(0,0,0,0.45);
}

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: visible;
}
