.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
}

.avatar {
  border-radius: 4px;
  object-fit: cover;
}

.avatar :global(span) {
  color: #B11873;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.avatar :global(img) {
  object-fit: cover;
}

.details {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.name {
  color: #070F17;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.position {
  color: #83878B;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

