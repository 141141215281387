.wrapper {
  background: #fff;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  color: #83878b;
  font-weight: 300;
  display: flex;
  flex-direction: column;
}

.hide {
  display: none;
}

.main {
  /*height: 100%;*/
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoImage {
  width: 100%;
  object-fit: contain;
  margin-bottom: 25px;
}

.tabs {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.4);
  transition: 0.07s;
}

.tabSignIn {
  font-weight: 700;
  text-align: center;
  color: #83878b;
  border-bottom: 3px solid #e7e7e8;
}

.tabSignUp {
  font-weight: 700;
  text-align: center;
  color: #83878b;
  border-bottom: 3px solid #e7e7e8;
}

.tabSignIn:hover,
.tabSignUp:hover {
  cursor: pointer;
}

.tabActive {
  color: #070f17;
  padding-bottom: 10px;
  border-bottom: 3px solid #b11873;
}

.form {
  width: 100%;
  color: #83878b;
  /*min-height: 350px;*/
}

.fail {
  display: none;
  height: 1.25rem;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  opacity: 0;
  margin-bottom: 10px;
  transition: 0.2s ease-in-out;
}

.failActive {
  display: flex;
  color: firebrick;
  height: 2.5rem;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
  /*margin-top: 1.5rem;*/
}

.buttonGoogleContainer {
  position: relative;
  margin: 8px 0 0;
}

.buttonGoogle {
  background: transparent !important;
  color: #070f17 !important;
  border: 1px solid #070f17 !important;
}

.buttonGoogleIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 2;
}

.rememberContainer {
  display: flex;
  align-items: center;
}

.rememberText {
  margin-left: 0.5rem;
  padding: 0 0.5rem;
}

.forgotText {
  text-align: right;
}

.signInWithContainer {
  margin-top: 1.5rem;
  text-align: center;
}

.servicesContainer {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.placeholderContainer {
  display: flex;
  align-items: center;
  color: black;
}

.placeholderImage {
  height: 16px;
  color: black;
}

.placeholderText {
  margin-left: 5px;
  color: black;
}

.checkboxLine {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  align-items: center;
}

.agreeWith {
  font-weight: bold;
}

.footer {
  /*height: 100px;*/
  /*position: fixed;*/
  /*bottom: 0;*/
}
