.wrapper {
  width: 100%;
  padding: 6px 20px;
  border-bottom: 1px solid #e7e7e8;
}

.breadcrumbs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
}

.link:hover {
  text-decoration: none;
}

.button {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #070f17;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.buttonActive {
  color: #b11873;
}

.divider {
  font-size: 12px;
  line-height: 16px;
}
