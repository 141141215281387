.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.tab {
    color: #aeaeae;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 0.1rem;
    margin-right: 1rem;
    margin-bottom: 1.2rem;
    white-space: nowrap;
}

.tab.active {
    color: #b11873;
    border-bottom: 0.2rem solid #b11873;
    margin-bottom: 1rem;
}

.row {
    display: flex;
    /*margin: 1rem;*/
    padding: 1rem 1rem 0 1rem;
    justify-content: space-between;
    align-content: center;
}

@media only screen and (max-width: 800px) {
    .row {
        flex-direction: column;
    }
}

.tabs {
    position: relative;
    display: flex;
    flex: 1;
}

.tabs::before {
    content: '';
    position: absolute;
    left: -20px;
    bottom: 16px;
    width: 100vw;
    height: 1px;
    background: #e7e7e8;
}

.controllers {
    /*height: 30px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /*flex-grow: 1;*/
    gap: 0.5rem;
    justify-content: flex-end;
    flex: 1;
}
.controllersWrapper {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    display: flex;
}

.controller {
    flex-grow: 0;
}

@media only screen and (max-width: 1077px) {
    .container {
        max-width: calc(100vw - 78px);
        background-color: #f3f3f4;
    }
    .controller {
        margin-left: 0;
    }
}
