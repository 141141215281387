.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.searchButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f4;
  margin: 0;
  padding: 0;
  border: 1px solid #e7e7e8;
  border-top-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 4px;
  outline: none;
  cursor: pointer;
}

.searchInput {
  width: 100%;
  padding-left: 12px;
  padding-right: 45px;
  border: 1px solid #e7e7e8;
  border-right: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
}

.searchLensImage {
  position: absolute;
  right: 16px;
  color: #909090;
}
