.subscription {
  user-select: none;
  background-color: white;
  padding: 20px;
  background-color: #fff;
  height: 500px;
  border-radius: 10px;
  background-image: url(https://wp.fastboss.ai/wp-content/uploads/2021/05/featured-bg.png),linear-gradient(180deg,#8300e9 0%,#e100ff 100%);
  background-color: #8300e9;
    .price {
      display: block;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      color: #ffffff;
      margin: 20px 0 5px 0;
    };
}
.header{
  font-family: Roboto;
  font-style: normal;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.1em;
}
.description{
  font-family: Roboto;
  font-style: normal;
  color: #ffffff;
  white-space: pre-line;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.description:not(:last-child){
  margin-bottom: 10px;
}
.goBackButton {
  max-width: 120px;
  padding: 0 16px;
  text-transform: uppercase;
}
.goBackButtonIcon {
  font-size: 12px;
  transform: translateX(-2px);
}
.goBackButtonWrapper {
  position: absolute;
  left: 0;
}
.paymentForm{
  display: block;
}
.line{
  width: 100%;
  height: 1px;
  background-color: #CDCFD1;
  margin-bottom: 10px;
}
.priceDescription{
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 290px;
  text-align: left;
  background-color: #fff;
}
.button{
  width: auto;
}
body{
  background-color: #F3F3F4
}

.curSubsWrapper {
  background: url(./images/current-subs-bg.png);
  border-radius: 5px;
  padding: 20px;
}

.curSubsTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
}

.curSubsDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.monthButton {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-transform: capitalize !important;
}

.yearButton {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-transform: capitalize;
}

.containerButton {
  display: block;
}

.inactiveButton {
  background-color: #E8BAD5 !important;
  color: #B11873 !important;
}

.cardWrapper {
  background-color: #ffffff;
  border: 1px solid rgba(7, 15, 23, 0.1);
  border-radius: 4px;
}

.cardWrapperTransparent {
  border: 1px solid transparent;
}

.cardHeader {
  height: 320px;
}

.cardHeaderContent {
  height: 320px;
}

.cardFooter {
  height: 60px;
}

.cardFooterContent { 
  display: flex;
  align-items: center;
  height: 60px;
}

.mainDescriptionList {
  height: 65px;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.planDescriptionList {
  height: 65px;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCheckbox {
  height: 15px;
}

.cardCheckbox span {
  background-color: #B11873;
}

.cardCheckbox span::after {
  border: solid #ffffff !important;
  border-width: 0 2px 2px 0 !important;
}

.cardForm {
  width: 60%;
}

.cardPriceContainer {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.priceSum {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.pricePeriod {
  align-self: end;
}

.cardInput {
  width: 50%;
  margin: 20px auto 0 auto;
}

.cardDescription {
  width: 250px;
  margin: 10px auto;
}

