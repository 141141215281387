.card {
    border: 1px solid rgba(7, 15, 23, 0.3);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}
.title {
    font-weight: bold;
    padding:  12px;
    display: flex;
    justify-content: space-between;
    user-select: none;

}
.content {
    padding: 0 12px 8px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.placeHolder {
    background-color: white;
    position: relative;
    width: 100%;
    height: 100%;
}

.contentBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px
}
