.wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.card {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
}

.cardHeading,
.cardMain {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  cursor: pointer;
}

.slideButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f4;
  color: #070f17;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.slideButton:focus {
  outline: none;
}

.slideButtonIcon {
  font-size: 8px;
  transition: transform 300ms ease;
}

.title {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.details {
  display: flex;
  flex-flow: row nowrap;
  font-size: 14px;
  line-height: 16px;
  column-gap: 4px;
  margin: 0;
}

.accordion {
  display: flex;
  flex-flow: column;
  width: 100%;
  overflow: hidden;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: max-height;
}

.accordionContent {
  padding: 12px 0;
}

.accordionContent > :global(div:last-of-type) {
  background: #ffffff;
  padding: 0px 0;
  border: 1px solid #cdcfd1;
  border-top: 0;
  border-bottom: 0;
  border-radius: 4px;
}

.accordionContent :global(table > thead > tr > th:first-of-type),
.accordionContent :global(table > tbody > tr > td:first-of-type) {
  padding-left: 20px;
  padding-right: 20px;
}

.accordionContent :global(table > thead > tr:first-of-type) {
  border-top: 1px solid #cdcfd1 !important;
}

.accordionContent :global(table > thead > tr:first-of-type > th) {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
