.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 9px 20px 11px !important;
}

.filters {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.filterButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b11873;
  color: white;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.filterButtonIcon {
  font-size: 13px;
}

.main {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
}

.line {
  display: flex;
  min-width: 1px;
  height: 36px;
  background: #e7e7e8;
}

.addButton {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  padding: 8px 16px 8px 18px !important;
  text-transform: capitalize !important;
}

.search {
  width: 13.88vw;
  min-width: 200px;
}

.search :global(button) {
  cursor: none;
  pointer-events: none;
}
