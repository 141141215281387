.wrapper {
  padding: 20px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 600px;
}

.titleWrapper {
  display: flex;
  flex-flow: row;
  column-gap: 8px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 6px 10px 0;
}

.titleId {
  color: #83878b;
  font-weight: 500;
  margin-right: 5px;
}

.titleButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 26px;
  height: 26px;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.titleButton:focus {
  outline: none;
}

.layout {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.layout + .layout {
  margin: 16px 0 0;
}

.row {
  width: 100%;
}

.column {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  row-gap: 16px;
}

.form {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
}

.form > :global(div) {
  flex: 49%;
}

.cardHeadingMain {
  width: calc(100% + 20px);
  margin: 0 0 10px;
}

.table {
  margin: -8px 0 0;
}

.cardButtons {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 0;
}

.cardButtons > :global(div) {
  width: auto !important;
  height: auto !important;
}

.button {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  padding: 8px 26px 8px 28px !important;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 10px 0 0;
}

.descriptionEmpty {
  color: #83878b;
  font-style: italic;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 15px;
}

.property {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 0 0 5px;
}

.propertyLabel {
  width: 121px;
  color: #83878b;
  font-size: 14px !important;
  font-size: 20px;
  margin: 0;
}

.propertyValue {
  width: 121px;
  font-size: 14px !important;
  font-size: 20px;
  margin: 0;
  white-space: pre;
}
