.title {
    font-weight: 700;
    font-size: 0.8rem;
    color: #656565;
}

.form {
    padding: 0 1rem;
    width: 700px;
}

.area {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
    border-radius: 0.2rem;
    width: 100%;
    height: 150px;
    resize: none;
    outline: none;
    border: 1px solid #eeeeee;
    background-color: #F8F8F8;
}