.container {
    padding: 0 1rem;
    /*height: 800px;*/
    width: 100%;
    margin-top:10px;
}

.header {
    font-weight: 700;
    background-color: #aeaeae;
}

.cell {
    height: 100%;
    width: 100%;
    padding: 5px 10px;
    white-space: pre-wrap;
    font-size: 0.8rem;
    overflow: auto;
    display: flex;
    align-items: center;
    /*height: 60px;*/
    /*max-height: 60px;*/
    /*min-height: 60px;*/
}

.wrapper{
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
}
.progressLine{
    background-color:#32CD32;
    bottom: 0;
    color: #fff;
    left: 0;
    top: 0;
    position: absolute;
    text-align: center;
    font-size: 16px;
    color: #0D0D0D;
}
.progressLineBG{
    background-color: #ccc;
    border-radius: 10px;
    height: 20px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.cell.editable {
    /*background-color: #ECF4F7;*/
}

.cell.editable:focus {
    background-color: rgba(32, 198, 255, 0.21);
}

.placeholder {
    position: absolute !important;
    margin-left: -1px;
    margin-bottom: -2px;
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    border: 1px solid rgba(114, 28, 36, 0.35);
    background-color: #FFEFEF;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
}

.placeholder .undo {
    padding-left: 0.3rem;
    color: #1596C0;
}

.placeholder .undo:hover {
    cursor: pointer;
    text-decoration: underline;
}

.placeholder .code {
    font-weight: 700;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

.placeholder .close {
    position: absolute;
    cursor: pointer;
    right: 22px;
    font-size: 25px;
    color: rgba(114, 28, 36, 0.35);
}

.placeholder .close:hover {
    color: rgba(114, 28, 36, 0.61);
}

.controlCell {
    padding: 0;
    /*height: 100%;*/
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.controlCell .save {
    cursor: pointer;
    font-size: 23px;
    color: #38b54a;
}

.controlCell .cancel {
    cursor: pointer;
    font-size: 23px;
    color: rgba(114, 28, 36, 0.61);
}

.controlIcon {
    font-size: 1rem;
    color: #888;
    cursor: pointer;
    transition-duration: .3s;
}
.controlIcon:hover {
    color: #1596C0;
}
.toolBar {
    display: flex;
    background: #f7f7f7;
    border: 2px solid #ececec;
    border-radius: 8px;
    padding: 4px;
    margin-bottom: 8px;
}
.toolBarItem {
    display: flex;
    align-items: center;
    color: #5c5c5f;
    cursor: pointer;
    user-select: none;
    padding: 4px 8px;
    border-right: 2px solid #ececec;
}
.toolBarItem i {
    margin-right: 4px;
}

.controlIcon:hover {
    /*transform: scale(1.1);*/
}

.table :global(.rt-table) {
    background: #fbfbfb;
}
