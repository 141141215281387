.cardWrapper {
    width: 700px;
    margin: auto;
    background-color: #fff;
    border: 2px solid #B11873;
    border-radius: 8px;
    padding: 30px 20px 30px 20px;
} 

.header {
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0 1rem;
}

.headerLogo {
    width: 250px;
}

.modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
}

.codeInput{
    appearance: none;
}
  
.codeInput::selection {
    opacity: 0;
}

.codeInput::-webkit-outer-spin-button,
.codeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} 

.codeInputContainer {
    align-self: center;
}

.modalDescription {
    text-align: center;
}

.formOptionsContainers {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

.formOptionsContainers label {
    cursor: pointer;
}

.cardHeader {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.cardDate {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.cardButtonContainer {
    width: 40% !important;
    margin: auto !important;
}

.cardLabel {
    font-size: 18px;
}

.resendCode{
    display: block;
    margin-top:10px;
    cursor:pointer;
    font-weight: 700;
    opacity: 0.8;
}

.resendCodeDisabled{
    display: block;
    margin-top:10px;
    opacity: 0.4;
    cursor: default;
}

.bottom {
    display: flex;
    justify-content: center;
}