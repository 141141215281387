.nav {
    font-family: Roboto;
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;


    display: flex;
    background-color: #F3F3F4;
    box-sizing: border-box;
    padding: 16px 20px 0;
    user-select: none;
    border-bottom: 0.2rem solid  #E7E7E8;
}
.activeLink {
    .navMenuItem {
        position: relative;
        line-height: 20px;
        color: #070F17;
        padding-bottom: 10px;
        &::before{
            position: absolute;
            content: '';
            width: 100%;
            height: 3px;
            background: #B11873;
            bottom: -3px;
        }
    }
}

.navMenuItem {
    cursor: pointer;
    margin-right: 1rem;
    white-space: nowrap;
    font-style: normal;
    color: #83878B;
}

.link {
    text-decoration: none;
    &:hover {
        text-decoration: none;
        .navSubmenu {
            pointer-events: all;
            opacity: 1;
            margin-top: -12px;
        }
    }
}

.navSubmenu {
    display: flex;
    flex-direction: column;
    opacity: 0;
    margin-top: -40px;
    pointer-events: none;
    transition-duration: 0.2s;
    position: absolute;
    list-style: none;
    background-color: #fff;
    border-radius: 8px;
    z-index: 2000;
    box-shadow: 0 1.5px 2.2px rgba(0, 0, 0, 0.022), 0 3.6px 5.3px rgba(0, 0, 0, 0.032), 0 6.8px 10px rgba(0, 0, 0, 0.04),
        0 12.1px 17.9px rgba(0, 0, 0, 0.048), 0 22.6px 33.4px rgba(0, 0, 0, 0.058), 0 54px 80px rgba(0, 0, 0, 0.08);
}

.navSubmenuItem {
    padding: 8px 16px;
    &:hover {
        opacity: 0.5;
    }
}
