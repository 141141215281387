.cardHeadingMain {
  width: calc(100% + 20px);
  margin: 0 0 20px;
}

.cardSection {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}

.cardSection + .cardSection {
  margin-top: 20px;
}

.cardMembers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-content: space-between;
  gap: 16px;
}

.cardMemeber {
  min-width: 112px;
}

.cardMemberRemovable {
  position: relative;
  cursor: pointer;
  margin-right: auto;
}

.cardMemberRemovable::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%23E8BAD5'/%3E%3Cpath d='M20 12L12 20' stroke='%23B11873' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 12L20 20' stroke='%23B11873' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  border-radius: 4px;
  opacity: 0;
  transform: scale(0);
  transition: transform 150ms ease, opacity 250ms ease;
  z-index: 1;
}

.cardMemberRemovable:hover::before {
  opacity: 1;
  transform: scale(1);
}

.assignButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.assignButton:focus {
  outline: none;
}

.assignIconWrapper {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #8f1661;
  padding: 0;
  margin: 0;
  border: 1px solid #81155a;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.assignIcon {
  color: white;
  font-size: 19px;
}

.assignText {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.assignDropdown {
  position: absolute;
  background: white;
  border: 1px solid #cdcfd1;
  border-radius: 4px;
  margin: 8px 0 0;
  z-index: 2;
}

.assignHeader {
  padding: 16px 20px 10px;
}

.assignSearch :global(button) {
  pointer-events: none;
}

.assignCheckbox {
  width: 100%;
  padding: 5px 22px;
  margin: 0;
}

.assignCheckbox:first-of-type {
  padding-top: 10px;
}

.assignCheckbox:last-of-type {
  padding-bottom: 10px;
}

.assignDepartment {
  display: inline-flex;
  color: #83878b;
  font-weight: 400;
  margin: 0 0 0 4px;
}

.assignSearch {
  position: relative;
}

.assignSearchButton {
  appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
  min-width: 36px;
  background: #f3f3f4;
  margin: 0;
  padding: 0;
  border: 1px solid #e7e7e8;
  border-top-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 4px;
  outline: none;
  cursor: pointer;
}
