.flowItem {
    padding: 12px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #ccc;
    user-select: none;
    display: flex;
    gap: 8px
}

.flowTarget {
    cursor: pointer;
}

.flowsRow {
    display: flex;
    padding: 8px 16px;
    gap: 16px;
    flex-wrap: wrap;
}

.graphContainer {
    width: fit-content;
    height: fit-content;
}

.flowsWrapper {
    border: 1px solid rgba(7, 15, 23, 0.3);
    border-radius: 4px;
    height: fit-content;
}
