.wrapper {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  margin: 0;
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 0 22px;
}

.checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  width: 13px;
  height: 13px;
  border: 2px solid #cdcfd1;
  border-radius: 2px;
  transition: all 240ms ease;
}

.wrapper:hover .checkbox ~ .checkmark {
  border-color: #b11873;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.wrapper .checkbox:checked ~ .checkmark {
  background: #b11873;
  border-color: #b11873;
}

.wrapper .checkbox:checked ~ .checkmark:after {
  display: flex;
}

.wrapper .checkmark:after {
  top: 1px;
  left: 0px;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.55545 1.33325L3.66656 6.22214L1.44434 3.99992' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  animation: appear 300ms;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
