.container {
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  border: 1px solid #e7e7e8;
  text-align: left;
  border-radius: 4px;
  transition: all 100ms ease;
  z-index: 0;
}

.containerFocus {
  border-color: #b3b3b3;
}

.container:hover {
  border-color: #b3b3b3;
}

.placeholder {
  text-align: center;
  margin-left: 10px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
}

.input {
  position: relative;
  background-color: transparent !important;
  text-align: left;
  border: none;
  width: 100%;
  padding: 7px 15px 6px;
  outline: none;
  z-index: 1;
}

.input::placeholder {
  color: #83878B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.inputContainer {
  margin: 5px auto 0;
  z-index: 0;
}

.select {
  padding: 5px 0 0 0;
  outline: none;
}

.holderPlace {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  user-select: none;
  width: auto;
  padding-right: 5px;
  height: 13px;
  bottom: -13px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(7, 15, 23) !important;
  z-index: 3;
}

.holderPlaceRequired {
  color: #d92d15;
  margin: 0 0 0 2px;
}

.inputDate {
  position: relative;
  color: white;
  /* position: relative;
    width: 150px; height: 20px;
    color: white; */
}

.inputDate:before {
  content: attr(data-attribute);
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 14px;
  color: rgb(7, 15, 23);
}

.inputDate:after {
  content: '';
  position: absolute;
  top: 12px;
  right: 10px;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z' stroke='%239C9FA2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.667 1.33325V3.99992' stroke='%239C9FA2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.33301 1.33325V3.99992' stroke='%239C9FA2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 6.66675H14' stroke='%239C9FA2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}

.inputDate::-webkit-datetime-edit,
.inputDate::-webkit-inner-spin-button,
.inputDate::-webkit-clear-button {
  display: none;
}

.inputDate::-webkit-calendar-picker-indicator {
  width: 100%;
  margin: 0 0 0 auto;
  opacity: 0;
  z-index: 10;
}
