.title {
    font-weight: 700;
    font-size: 0.8rem;
    color: #656565;
}

.form {
    padding: 0 1rem;
    width: 700px;
}

.tableHeader {
    text-align: left;
}

.tableHeader * {
    white-space: pre-wrap !important;
}

.backToCustomers {
    position: absolute;
    font-size: 2rem;
}

.plusActivity {
    transition: 150ms ease-in-out;
}

.plusActivity:hover {
    transform: scale(1.2);
}
