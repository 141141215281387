.table,
.table :global(thead),
.table :global(tbody),
.table :global(tfoot),
.table :global(tr),
.table :global(th),
.table :global(td) {
  vertical-align: inherit;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

.table {
  width: 100%;
}

.tableHeadHeading {
  color: #83878b;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 18px;
  padding: 7px 0;
  cursor: pointer;
}

.tableHeadHeading > :global(div) {
  min-height: 20px;
  cursor: pointer;
}

.tableHeadRow,
.tableBodyRow {
  border-bottom: 1px solid #cdcfd1 !important;
}

.tableHeadSortIcon {
  margin: 0 0 0 4px;
}

.tableBodyRow {
  height: 43px;
  cursor: pointer;
}

.tableBodyData {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 0;
}

.tableBodyDataDone {
  color: #83878b;
  text-decoration: line-through;
}

.table :global(.assignedMembers) {
  position: absolute;
  top: 50%;
  left: 0px;
  display: flex;
  flex-flow: row nowrap;
  min-height: 24px;
  max-height: 24px;
  overflow: hidden;
  transform: translateY(-50%);
}

.table :global(.assignedMembers div > div:last-of-type) {
  display: none;
}

.table :global(.assignedMembers img) {
  width: 24px;
  height: 24px;
}

.tableBodyRowActions {
  position: absolute;
  top: 50%;
  left: 0px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 2px;
  justify-content: flex-start;
  transform: translateY(-50%);
}

.tableBodyRowActions > .tableBodyRowActionButton:first-child {
  position: relative;
  top: 1px;
}

.tableBodyRowActionButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 22px;
  height: 22px;
  padding: 4px;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.tableBodyRowActionButtonIcon {
  width: 16px;
  height: 16px;
}
