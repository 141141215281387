.wrapper{
  text-align: center;
  padding-top: 1rem;
  white-space: pre-wrap;
}

.title{
  font-weight:900; 
  font-size:1.75rem;
}
.description{
  margin-bottom:10px;
  margin:0 auto;
  font-size: 1rem;
}

.code{
  text-align:center;
  margin:0 auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:300px;
}

.codeTitle{
  font-weight:bold;
  width:100%;
  margin-left:8px;
  margin-top:20px;
  font-size:14px;
  text-align:left;
}

.codeInput{
  appearance: none;
}

.codeInput::selection {
  opacity: 0;
}

.codeInput::-webkit-outer-spin-button,
.codeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.buttonWrapper{
  display:flex;
  justify-content:center;
  margin-top:23px
}
.errorFeedback{
  font-weight:bold;
  width:100%;
  color:#f85149;
  margin-left:8px;
  font-size:10px;
  text-align:left
}

.bottom{
  text-align:center;
  margin:0 auto;
  width:300px;
}
.resendCode{
  display: block;
  margin-top:10px;
  cursor:pointer;
  font-weight: 700;
  opacity: 0.8;
}
.resendCodeDisabled{
  display: block;
  margin-top:10px;
  opacity: 0.4;
  cursor: default;
}

.timerCountDown{
  display:block;
  opacity: 0.5;
}

.modal{
  width: 530px;
}

.red{
  color:#f85149
}

.bold{
  font-weight: 700;
}

.modalHeaderContent{
  padding:0;
  padding:16px 19px 0px 50px; 
  flex-direction: column;
}

.modalHeaderWrapper{
  padding:0;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.modalTitle{
  padding:0;
  line-height:120%;
  font-weight:bold;
  font-weight: 700;
  padding-top:32px;
}

.modalBody{
  border:none;
  padding:0px 66px 0px 66px;
  margin-top:15px;
  display: flex;
  flex-direction: column;
  gap:13px;
}

.modalParagraph{
  margin:0;
  text-align: left;
}
.modalCancelWrapper{
  display: flex;
  justify-content: center;
}
.modalFooterActions{
  width: 100%;
  gap:8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.modalFooter{
  border:none;
  padding:0px 66px 32px 66px; 
  margin-top:28px; 
  display:flex;
  flex-direction:column; 
  align-items:center
}

@media (max-width: 797px) {
  .modal{
    width: 100%;
    max-width: 530px;
    margin:0 auto;
  }
  .modalHeaderContent{
    padding:16px 0.7rem 0px 1.563rem; 
  }
  .modalBody{
    padding:0px 10% 0px 10%;
  }
  .modalFooter{
    padding:0px 10% 32px 10%; 
  }
}
