.wrapper {
  height: 100%;
  display: flex;
}

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - 60px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0.3rem 1rem;
  margin-bottom: 0.3rem;
}
.documentsWrapper {
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.header .name {
  position: relative;
  color: #b11873;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  padding-bottom: 0.1rem;
  border-bottom: 2px solid #b11873;
  cursor: pointer;
}

.header .name::before {
  content: '';
  position: absolute;
  left: -20px;
  bottom: -3px;
  width: 100vw;
  height: 1px;
  background: #e7e7e8;
}

.documentsList {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 0 !important;
}

.documentContainer {
  margin: 0 !important;
  border-bottom: 1px solid #cdcdcd;
  max-height: 50px !important;
  min-height: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.documentHead {
  min-height: auto;
}
.noDocs {
  padding-left: 0.5rem;
  /*font-size: 1.3rem;*/
  font-size: 16px;
  color: #a6a8a7;
}

.item {
  margin: 0;
  display: grid;
  grid-template-columns: 2fr 0.5fr 1fr 0.5fr 0.5fr 1fr 20px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  grid-template-areas:
    'title department author dateCreated dateUpdated status options'
    'title department author dateCreated dateUpdated status options';
  column-gap: 16px;
  padding: 8px;
}

.itemHead {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 0.5fr 1fr 0.5fr 0.5fr 1fr 20px;
  grid-template-rows: 1fr;
  align-items: center;
  grid-template-areas: 'title department author dateCreated dateUpdated status options';
  column-gap: 16px;
  padding: 8px;
}

.tableHead {
  font-size: 0.875rem;
  white-space: nowrap;
}
.documentHead {
  border: none;
}
.paginationPerPage {
  margin: 0;
}
.documentImage {
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentTitle {
  grid-area: title;
  min-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.documentTags {
  grid-area: tags;
  display: flex;
  gap: 4px;
  min-width: 237px;
}
.documentAuthor {
  grid-area: author;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.documentDepartment {
  grid-area: department;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.documentDateUpdated {
  grid-area: dateUpdated;
  font-size: 0.875rem;
  min-width: 102px;
}
.documentDateCreated {
  grid-area: dateCreated;
  font-size: 0.875rem;
  min-width: 102px;
}
.documentStatus {
  display: flex;
  grid-area: status;
  min-width: 144px;
  font-size: 0.875rem;
  text-align: center;
  border-radius: 4px;
  padding: 2px;
}
.headArrowIcon {
  margin-left: 4px;
  transition-duration: 0.3s;
  color: #888888;
}
.documentOptions {
  grid-area: options;
}
.tag {
  padding: 1px 9px;
  background: #f1f1f1;
  border-radius: 10px;
  color: black;
  opacity: 0.4;
  font-weight: 900;
  font-size: 0.75rem;
}
.documentAuthor {
  font-size: 0.875rem;
}
.Pagination {
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.item.mini .documentAuthor,
.item.mini .documentStatus,
.item.mini .documentTags {
  display: none;
  min-width: auto;
}
.item.mini .documentOptions {
  margin-right: 20px;
  min-width: auto;
}
.item.mini .documentImage,
.item.mini .documentTitle {
  min-width: auto;
}
.item.mini {
  grid-template-columns: 1fr 20px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'title options'
    'dateUpdated dateUpdated';
  padding: 10px 0px;
}
.item.mini .documentDateCreated {
  display: none;
}

.buttonsContainer {
  padding: 0 0.5rem;
  display: flex;
}

.deleteSelected {
  width: 150px !important;
}

@media (max-width: 830px) {
  .documentDateCreated {
    display: none;
  }
  .item {
    grid-template-columns: 30px 1fr 1fr 0.1fr;
    grid-template-rows: auto auto auto;
    gap: 0px 0px;
    grid-template-areas:
      'title title title options'
      'tags tags tags tags'
      'status status dateUpdated dateUpdated';
    padding-left: 10px;
    padding-right: 10px;
    row-gap: 7px;
  }
  .documentHeadImage {
    display: none;
  }
  .documentAuthor {
    min-width: auto;
    display: none;
  }
  .tag {
    font-size: 0.7rem;
  }
  .documentHead {
    display: none;
  }
  .documentOptions {
    width: 10px;
    max-width: 50px;
    align-items: stretch;
    margin-left: auto;
  }
  .documentDateCreated {
    font-size: 0.75rem;
    min-width: auto;
  }
  .documentDateUpdated {
    font-size: 0.75rem;
    min-width: auto;
    text-align: right;
  }
  .documentTitle {
    min-width: auto;
    max-width: auto;
  }
  .documentTags {
    min-width: 236px;
  }
  .documentStatus {
    font-size: 0.75rem;
    align-self: flex-end;
    min-width: auto;
  }
  .documentContainer {
    max-height: 90px;
    min-height: 90px;
  }
}

@media (max-width: 620px) {
  .Pagination {
    display: flex;
    flex-direction: column;
  }
  .paginationPerPage {
    margin-top: 10px;
  }
  .perPage {
    margin-top: 10px;
  }
}

.itemHead {
  padding: 10px;
}
.documentTagsHeader {
  display: none;
}

.filter {
  display: none;
}

.filterOpen {
  position: absolute;
  right: 0;
  height: 100%;
  display: block;
  flex-direction: column;
  border-left: 1px solid rgba(217, 217, 217, 0.51);
  background-color: white;
  max-width: 440px;
  min-width: 440px;
}

.filterClose {
  display: block;
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
}

.filterClose::after,
.filterClose::before {
  content: '';
  height: 2px;
  width: 20px;
  display: block;
  background-color: #676767;
  position: absolute;
  top: 50%;
}

.filterClose::after {
  transform: rotate(45deg);
}

.filterClose::before {
  transform: rotate(-45deg);
}

.filterHeader {
  padding: 24px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.51);
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
}
.filterBody {
  padding: 24px;
}

@media (max-width: 826px) {
  .filterHeader {
    padding: 24px 25px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.51);
  }
  .filterBody {
    padding: 24px 25px;
  }
}

.filterAction {
  margin-top: auto;
  display: flex;
  gap: 4px;
  padding: 0 24px 16px;
}

.PaginationElement {
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
}

/* .PaginationElement :global(ul),
:global(.rc-pagination) :global(ol) {
  margin: 0;
  padding: 0;
  list-style: none;
}
.PaginationElement::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: " ";
}

.PaginationElement > :global(.rc-pagination-item) {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.PaginationElement :global(.rc-pagination-item a) {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.PaginationElement :global(.rc-pagination-item a:hover) {
  text-decoration: none;
}
.PaginationElement :global(.rc-pagination-item:focus, .rc-pagination-item:hover) {
  border-color: #1890ff;
  transition: all 0.3s;
}
.PaginationElement :global(.rc-pagination-item:focus a, .rc-pagination-item:hover a) {
  color: #1890ff;
}
.PaginationElement :global(.rc-pagination-item-active) {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}
.PaginationElement :global(.rc-pagination-item-active a) {
  color: #1890ff;
}
.PaginationElement :global(.rc-pagination-item-active:focus, .rc-pagination-item-active:hover) {
  border-color: #40a9ff;
}
.PaginationElement :global(.rc-pagination-item-active:focus a, .rc-pagination-item-active:hover a) {
  color: #40a9ff;
}
.PaginationElement :global(.rc-pagination-jump-prev, .rc-pagination-jump-next) {
  outline: 0;
}
.PaginationElement :global(.rc-pagination-jump-prev button, .rc-pagination-jump-next button) {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}
.PaginationElement :global(.rc-pagination-jump-prev button:after, .rc-pagination-jump-next button:after) {
  display: block;
  content: "•••";
}
.PaginationElement :global(.rc-pagination-prev),
:global(.rc-pagination-jump-prev),
:global(.rc-pagination-jump-next) {
  margin-right: 8px;
}
.PaginationElement :global(.rc-pagination-prev),
:global(.rc-pagination-next),
:global(.rc-pagination-jump-prev),
:global(.rc-pagination-jump-next) {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.PaginationElement :global(.rc-pagination-prev),
:global(.rc-pagination-next) {
  outline: 0;
}
.PaginationElement :global(.rc-pagination-prev button),
:global(.rc-pagination-next button) {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}
.PaginationElement :global(.rc-pagination-prev:hover button),
:global(.rc-pagination-next:hover button) {
  border-color: #40a9ff;
}
.PaginationElement :global(.rc-pagination-prev) :global(.rc-pagination-item-link),
:global(.rc-pagination-next) :global(.rc-pagination-item-link) {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.PaginationElement :global(.rc-pagination-prev:focus) :global(.rc-pagination-item-link),
:global(.rc-pagination-prev:hover) ::global(.rc-pagination-item-link),
::global(.rc-pagination-next:focus) :global(.rc-pagination-item-link),
:global(.rc-pagination-next:hover) :global(.rc-pagination-item-link) {
  color: #1890ff;
  border-color: #1890ff;
}
.PaginationElement :global(.rc-pagination-prev) :global(button:after) {
  content: "‹";
  display: block;
}
.PaginationElement :global(.rc-pagination-next) :global(button:after) {
  content: "›";
  display: block;
}
.PaginationElement :global(.rc-pagination-disabled),
:global(.rc-pagination-disabled:hover),
:global(.rc-pagination-disabled:focus) {
  cursor: not-allowed;
}
.PaginationElement :global(.rc-pagination-disabled) :global(.rc-pagination-item-link),
:global(.rc-pagination-disabled:hover) ::global(.rc-pagination-item-link),
:global(.rc-pagination-disabled:focus) :global(.rc-pagination-item-link) {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.PaginationElement :global(.rc-pagination-slash) {
  margin: 0 10px 0 5px;
}
.PaginationElement :global(.rc-pagination-options) {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.PaginationElement :global(.rc-pagination-options-size-changer):global(.rc-select) {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.PaginationElement :global(.rc-pagination-options-quick-jumper) {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}
.PaginationElement :global(.rc-pagination-options-quick-jumper) :global(input) {
  width: 50px;
  margin: 0 8px;
}
.PaginationElement :global(.rc-pagination-simple) :global(.rc-pagination-prev),
:global(.rc-pagination-simple) :global(.rc-pagination-next) {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.PaginationElement :global(.rc-pagination-simple) :global(.rc-pagination-prev) :global(.rc-pagination-item-link),
:global(.rc-pagination-simple) :global(.rc-pagination-next) :global(.rc-pagination-item-link) {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.PaginationElement :global(.rc-pagination-simple) :global(.rc-pagination-prev) :global(.rc-pagination-item-link::after),
:global(.rc-pagination-simple) :global(.rc-pagination-next) :global(.rc-pagination-item-link::after) {
  height: 24px;
  line-height: 24px;
}
.PaginationElement :global(.rc-pagination-simple) :global(.rc-pagination-simple-pager) {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.PaginationElement :global(.rc-pagination-simple) :global(.rc-pagination-simple-pager) :global(input) {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.PaginationElement :global(.rc-pagination-simple) :global(.rc-pagination-simple-pager) :global(input:hover) {
  border-color: #1890ff;
}
.PaginationElement:global(.rc-pagination-disabled) {
  cursor: not-allowed;
}
.PaginationElement:global(.rc-pagination-disabled) :global(.rc-pagination-item) {
  background: hsv(0, 0, 96%);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.PaginationElement:global(.rc-pagination-disabled) :global(.rc-pagination-item) :global(a) {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.PaginationElement:global(.rc-pagination-disabled) :global(.rc-pagination-item-active) {
  background: red;
  border-color: transparent;
}
.PaginationElement :global(.rc-pagination-disabled) :global(.rc-pagination-item-active) :global(a) {
  color: #fff;
}
.PaginationElement:global(.rc-pagination-disabled) :global(.rc-pagination-item-link) {
  color: rgba(0, 0, 0, 0.25);
  background: hsv(0, 0, 96%);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.PaginationElement:global(.rc-pagination-disabled) :global(.rc-pagination-item-link-icon) {
  opacity: 0;
}
.PaginationElement:global(.rc-pagination-disabled) :global(.rc-pagination-item-ellipsis) {
  opacity: 1;
} */
