._row_ {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 8px;
}

.row {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
}
