/* Hide the CKEditor logo */
:global(.ck-powered-by-balloon) {
  opacity: 0;
}

.documentEditor {
  /* border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius); */
  border: 1px solid #cdcfd1;
  border-radius: 4px;
  overflow: hidden;

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.documentEditor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  /* box-shadow: 0 0 5px hsla( 0,0%,0%,.2 ); */

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.documentEditor__toolbar :global(.ck-toolbar) {
  border: 0 !important;
  border-radius: 0 !important;
}

/* Make the editable container look like the inside of a native word processor application. */
.documentEditor__editableContainer {
  padding: calc( 2 * var(--ck-spacing-large) );
  background: var(--ck-color-base-foreground);

  /* Set the dimensions of the "page". */
  /* width: calc( 21cm + 2 * var(--ck-spacing-large) ); */
  width: 100%;

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.documentEditor__editableContainer :global(.ck-content.ck-editor__editable) {
  /* Set the dimensions of the "page". */
  width: 100% !important;
  max-width: calc(21cm + 32px) !important;
  min-height: 29.7cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1.27cm !important;

  border: 1px hsl( 0,0%,82.7% ) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

  /* Center the "page". */
  margin: 0 auto;
}

/* Set the default font for the "page" of the content. */
.documentEditor :global(.ck-content),
.documentEditor :global(.ck-heading-dropdown .ck-list .ck-button__label) {
    font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.documentEditor :global(.ck-heading-dropdown .ck-list .ck-button__label) {
  line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.documentEditor :global(.ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label) {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.documentEditor :global(.ck-content h2),
.documentEditor :global(.ck-heading-dropdown .ck-heading_heading1 .ck-button__label) {
  font-size: 2.18em;
  font-weight: normal;
}

.documentEditor :global(.ck-content h2) {
  line-height: 1.37em;
  padding-top: .342em;
  margin-bottom: .142em;
}

/* Set the styles for "Heading 2". */
.documentEditor :global(.ck-content h3),
.documentEditor :global(.ck-heading-dropdown .ck-heading_heading2 .ck-button__label) {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl( 203, 100%, 50% );
}

.documentEditor :global(.ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label) {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.documentEditor :global(.ck-content h3) {
  line-height: 1.86em;
  padding-top: .171em;
  margin-bottom: .357em;
}

/* Set the styles for "Heading 3". */
.documentEditor :global(.ck-content h4),
.documentEditor :global(.ck-heading-dropdown .ck-heading_heading3 .ck-button__label) {
  font-size: 1.31em;
  font-weight: bold;
}

.documentEditor :global(.ck-content h4) {
  line-height: 1.24em;
  padding-top: .286em;
  margin-bottom: .952em;
}

/* Set the styles for "Paragraph". */
.documentEditor :global(.ck-content p) {
  font-size: 1em;
  line-height: 1.63em;
  /* padding-top: .5em; */
  /* margin-bottom: 1.13em; */
}

/* Make the block quoted text serif with some additional spacing. */
.documentEditor :global(.ck-content blockquote) {
  font-family: Georgia, serif;
  margin-left: calc( 2 * var(--ck-spacing-large) );
  margin-right: calc( 2 * var(--ck-spacing-large) );
}

/* Set the styles for PDF generation. */
.documentEditor :global(.ck-content) {
  font: 16px / 1.6 'Roboto', Helvetica, Arial, sans-serif;
  width: 100%;
}
.documentEditor :global(.ck-content > *) {
  width: 100%;
}
.documentEditor :global(.ck-content *) {
  break-inside: avoid;
  break-after: auto;
}
.documentEditor :global(.ck-content figure.table) {
  width: 100%;
}
.documentEditor :global(.ck-content .table thead) {
  display: table-row-group;
}
.documentEditor :global(.ck-content .table tr) {
  break-inside: avoid;
  break-after: auto;
}
