.controllers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
.grid {
    display: flex;
    gap: 8px;
}

.error {
    color: firebrick;
}
