.container {
  height: 100%;
  display: flex;
  background-color: #f3f3f4;
}

.tabs {
  width: 100%;
  overflow-y: auto;
}

.main {
  position: relative;
  min-width: 500px;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.checkbox{
  margin: auto;
}

.deleteSelected{
  width: 150px;
}
.buttonContainer{
  padding: 0 0.5rem;
  width: 150px;
}

.createYourFirst {
  padding-left: 0.5rem;
  font-size: 1.1rem;
  color: #a6a8a7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75vh;
}

.boardContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 15px;
}

.board {
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.boardHeader {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.headerName {
  white-space: nowrap;
  font-weight: 700;
}

.switch {
  font-weight: 300;
  font-size: 14px;
  color: #aaaaaa;
  border-bottom: 1.5px solid rgba(186, 186, 186, 0.51);
  cursor: pointer;
  transition: 0.1s;
}

.seeAll:hover {
  border-color: rgba(186, 186, 186, 1);
}

.createNew {
}
.switchButtons {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 0.5rem;
  background-color: #ececec;
  border-radius: 14px;
}

.createNewButton {
  font-weight: 400;
  font-size: 15px;
}

.boardItem {
  user-select: none;
  cursor: pointer;
  position: relative;
  height: 150px;
  margin: 0.5rem;
  padding: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.15rem;
  background-color: #f0f4f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.boardItem .mainInfo {
  flex: 0;
}

.boardItemText {
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.5rem;
  max-height: 4.5rem;
  padding-right: 2rem;
}

.date {
  font-size: 0.7rem;
  color: #5c5c5f;
}

.boardItem .keywords {
  font-size: 0.9rem;
  line-height: 1rem;
  max-height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5c5c5f;
  padding-right: 2rem;
}

.boardItemIcon {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.latestDocuments {
  position: relative;
  overflow: auto;
  width: 280px;
  min-width: 280px;
  border-left: 1px solid rgba(217, 217, 217, 0.7);
}

.listContainer {
  width: 100%;
  position: absolute;
  padding: 1rem;
}

.listHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  height: 2rem;
}

.listItem {
  position: relative;
  user-select: none;
  display: flex;
  padding: 0;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out 0.1s;
  margin-bottom: 1rem;
}

.listItemText {
  font-size: 0.9rem;
  line-height: 1.2rem;
  max-height: 2.4rem;
  overflow: hidden;
  font-weight: 400;
}

.listItemIconContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 55px;
}

.listItemContentContainer {
  flex: 1;
}

.boardItem.pressing,
.listItem.pressing {
  opacity: 0.2;
}

.noDocs {
  color: #a6a8a7;
  font-size: 16px;
}

.dialogBackground {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogWrapper {
  background-color: white;
  width: 600px;
  height: 360px;
  border-radius: 8px;
  display: flex;
  padding: 16px;
}
.dialogGrid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content min-content 1fr min-content;
}

.buttons {
}
.title {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem;
}
.error {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: firebrick;
  transition-duration: 0.5s;
}

.searchBar {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 0.5rem;
  width: 100%;
}
.input {
  padding-top: 0.7rem;
  height: 100%;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hint {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 1.2rem;
}

.users {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  overflow-x: auto;
  max-height: 8rem;
}
.userItem {
  display: grid;
  width: fit-content;
  padding: 0.5rem;
  margin: 0.5rem;
  grid-gap: 0.5rem;
  grid-template-columns: min-content min-content;
  height: min-content;
  background-color: #ececec;
  border-radius: 0.5rem;
  transition-duration: 0.3s;
}
.removeButton {
  cursor: pointer;
  transition-duration: 0.3s;
}

.removeButton:hover {
  color: firebrick;
}
.removeButton:active {
  opacity: 0.2;
  transition-duration: 0s;
}

@media (max-width: 1520px) {
  .latestDocuments {
    display: none;
  }
}

.documentContainer {
  max-height: 50px;
  min-height: 50px;
  background-color: white;
  height: auto;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.documentHead {
  border: none;
  min-height: auto;
}
.itemHead {
  padding: 0;
  white-space: nowrap;
}

.item {
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 4fr 1fr 0.5fr 0.5fr 30px;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "title email depName author keywords dateCreated dateUpdated options"
    "title email depName author keywords dateCreated dateUpdated options";
  column-gap: 16px;
  padding: 10px;
  margin: 0;
}

.documentEmail {
  grid-area: email;
  min-width: 150px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 14px;
}

.documentImage {
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentTitle {
  grid-area: title;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.documentDepartment {
  grid-area: depName;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.documentAuthor {
  grid-area: author;
  min-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.documentTags {
  grid-area: tags;
  display: flex;
  gap: 4px;
  min-width: 150px;
}

.tag {
  padding: 1px 9px;
  background: #f1f1f1;
  border-radius: 10px;
  color: black;
  opacity: 0.4;
  font-weight: 900;
  font-size: 0.75rem;
}
.documentDateCreated {
  grid-area: dateCreated;
  font-size: 0.875rem;
  min-width: 92px;
}
.documentDateUpdated {
  grid-area: dateUpdated;
  font-size: 0.875rem;
  min-width: 92px;
}
.keyword {
  font-size: 0.875rem;
}
.documentKeyWords {
  grid-area: keywords;
  min-width: 150px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 14px;
}
.documentOptions {
  grid-area: options;
}
.documentTagsHeader {
  display: none;
}
.itemHead {
  border: none;
  font-size: 0.875rem;
  grid-template-rows: 1fr;
  padding: 10px 10px;
  cursor: default;
}

.headArrowIcon {
  margin-left: 4px;
  transition-duration: 0.3s;
  color: #888888;
}

@media (max-width: 980px) {
  .itemHead {
    display: none;
  }
  .item {
    padding: 10px;
    grid-template-columns: 1fr 1fr 1fr 45px 23px;
    grid-template-rows: auto auto;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas:
      "title title title title options"
      "tags tags tags tags tags"
      "keywords keywords keywords dateUpdated dateUpdated";
    grid-row-gap: 7px;
    row-gap: 7px;
  }
  .documentDateUpdated {
    min-width: auto;
  }
  .documentOptions {
    width: 15px;
    margin-left: auto;
  }
  .documentTags {
    min-width: auto;
  }
  .documentDateCreated {
    display: none;
  }
  .documentDateUpdated {
    align-self: center;
    text-align: right;
    font-size: 0.75rem;
  }
  .documentTitle {
    grid-area: title;
    min-width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
  }
  .documentKeyWords {
    font-size: 0.75rem;
    min-width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 0px;
    opacity: 0.8;
  }
  .documentContainer {
    max-height: 100px;
    min-height: 100px;
  }
  .documentHead {
    display: none;
  }
}

.filter {
  display: none;
}

.filterOpen {
    position: absolute;
    right: 0;
    height: 100%;
    display: block;
    flex-direction: column;
    border-left: 1px solid rgba(217, 217, 217, 0.51);
    background-color: white;
    max-width: 440px;
    min-width: 440px;
}

.filterClose {
  display: block;
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
}

.filterClose::after,
.filterClose::before {
  content: "";
  height: 2px;
  width: 20px;
  display: block;
  background-color: #676767;
  position: absolute;
  top: 50%;
}

.filterClose::after {
  transform: rotate(45deg);
}

.filterClose::before {
  transform: rotate(-45deg);
}

.filterHeader {
  padding: 24px 25px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.51);
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
}
.filterBody {
  padding: 24px 25px;
}

.contextMenu {
  position: fixed;
  width: 100px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 9999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.contextMenu .contextMenuItem:hover {
  background: #cccccc;
}
.contextMenuItem:hover:not(.contextMenuItem--disabled) {
  background-color: #f1f1f1;
}

.contextMenu .contextMenuItem {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

@media (max-width: 826px) {
  .filterHeader {
    padding: 24px 25px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.51);
  }
  .filterBody {
    padding: 24px 25px;
  }
}

@media (max-width: 620px) {
  .Pagination {
    display: flex;
    flex-direction: column;
  }
  .paginationPerPage {
    margin-top: 10px;
  }
  .perPage {
    margin-top: 20px;
  }
}

.filterAction {
  margin-top: auto;
  display: flex;
  gap: 4px;
  padding: 0 24px 16px;
}

.documentTagsHeader {
  display: none;
}
