.wrapper {
  padding: 20px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 600px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 10px;
}

.layout {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.column {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  row-gap: 16px;
}

.form {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
}

.form > :global(div) {
  flex: 49%;
}

.form > :global(div:nth-of-type(2)),
.form > :global(div:nth-of-type(3)) {
  flex: 24%;
}

.cardHeadingMain {
  width: calc(100% + 20px);
  margin: 0 0 20px;
}

.radioboxGroup {
  display: flex;
  flex-flow: column;
  row-gap: 8px;
}

.radioboxLabel {
  color: #393f45;
  font-weight: 400;
}

.buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 12px;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #cdcfd1;
  padding: 12px 16px;
}

.buttons > :global(div) {
  width: auto !important;
  height: auto !important;
}

.button {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  padding: 8px 26px 8px 28px !important;
}

@media screen and (max-width: 1920px) {
  .column.columnMain {
    flex: 0 60%;
  }
}

@media screen and (max-width: 1440px) {
  .column.columnMain {
    flex: 0 66.2068%;
  }
}
