.wrapper {
  display: flex;
  flex-flow: column;
  min-height: 500px;
}

.gantt {
  flex: 1;
  width: 100%;
  height: 100%;
}

.gantt :global(.gantt_link_arrow.gantt_link_arrow_right::before) {
  position: relative;
  top: -3px;
}

.gantt :global(.gantt_layout_cell.gantt_layout_root.gantt_layout) {
  margin-top: -10px;
  border: none;
}

.gantt :global(.gantt_task_link) {
  --dhx-gantt-link-background: #b11873;
}

.gantt :global(.gantt_task_link:hover) {
  --dhx-gantt-link-background: #070f17;
}

.gantt
  :global(
    .gantt_links_area
      > .gantt_task_link
      > .gantt_line_wrapper:first-child
      > .gantt_link_line_right
  ) {
  position: relative;
}

.gantt
  :global(
    .gantt_links_area
      > .gantt_task_link
      > .gantt_line_wrapper:first-child
      > .gantt_link_line_right
  ):before {
  content: '';
  position: absolute;
  top: -2px;
  left: -3px;
  width: 7px;
  height: 7px;
  background: #b11873;
  border-radius: 7px;
  z-index: 1;
}

.gantt :global(.gantt_task_line) {
  border-radius: 4px;
  overflow: hidden;
}

.gantt :global(.gantt_task_content) {
  font-weight: 600;
}

.gantt :global(.gantt_task_line.status--done .gantt_task_progress_wrapper),
.gantt :global(.gantt_task_line.status--done) {
  background: #e7e7e8;
}

.gantt :global(.gantt_task_line.status--done .gantt_task_progress) {
  background: #e7e7e8;
  opacity: 0.25;
}

.gantt :global(.gantt_task_line.status--done .gantt_task_content) {
  color: #83878b;
}

.gantt
  :global(.gantt_task_line.status--inprogress .gantt_task_progress_wrapper),
.gantt :global(.gantt_task_line.status--inprogress) {
  background: #e8bad5;
}

.gantt :global(.gantt_task_line.status--inprogress .gantt_task_progress) {
  background: #b11873;
  opacity: 0.25;
}

.gantt :global(.gantt_task_line.status--inprogress .gantt_task_content) {
  color: #b11873;
}

.gantt :global(.gantt_task_line.status--todo .gantt_task_progress_wrapper),
.gantt :global(.gantt_task_line.status--todo) {
  background: #fdd7ba;
}

.gantt :global(.gantt_task_line.status--todo .gantt_task_progress) {
  background: #fdd7ba;
  opacity: 0.25;
}

.gantt :global(.gantt_task_line.status--todo .gantt_task_content) {
  color: #f67716;
}
