.container {
    padding: 0 0.5rem;
    margin-top: -0.5rem;
}

.itemContainer {
    padding: 0.5rem;
}

.item {
    background: #fff;
    user-select: none;
    cursor: pointer;
    height: 160px;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 0.15rem;
    overflow: hidden;
}

.close {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.item .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 0.8rem;
    color: #5C5C5F;
    height: 40px;
    background-color: #f0f4f7;
}

.item .header div {
    line-height: 1.1;
}

.item .preview {
    height: 120px;
    font-size: 80px;
    color: #f0f4f7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item .buttonRemove {
    position: absolute;
    top: 8px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    background: none;
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0;
    border: none;
    outline: none;
    opacity: 0.7;
    z-index: 10;
    cursor: pointer;
}

.item .buttonRemove i {
    font-size: 16px;
} 

.item .date {
    color: #aaaaaa;
}

.no {
    color: #a6a8a7;
    padding-top: 0.3rem;
    padding-left: 0.5rem;
}
