.title {
    font-weight: 700;
    font-size: 0.8rem;
    color: #656565;
}

.form {
    padding: 0 1rem;
    width: 700px;
}

.pagination {
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
