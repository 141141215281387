.container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.dropdown {
  position: fixed;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  overflow: hidden;
  //transition: 0.2s ease-in-out;
  padding: 0.5rem 0;
  border-radius: 0.5rem;

  $shadowAlpha: 0.3;
  $shadowBlur: 6px;

  -webkit-box-shadow: 0px 0px $shadowBlur 0px rgba(0,0,0,$shadowAlpha);
  -moz-box-shadow: 0px 0px $shadowBlur 0px rgba(0,0,0,$shadowAlpha);
  box-shadow: 0px 0px $shadowBlur 0px rgba(0,0,0,$shadowAlpha);

  z-index: 100;
}

.dropdown.showing {
  min-width: 6rem;
  opacity: 1;
}

.dropItem {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  padding: 0.5rem 0.75rem;
  transition: 0.1s ease-in-out;
}

.dropItem:hover {
  background-color: #ECF4F7;
  cursor: pointer;
}

.dotsIcon {
  color: #87878b;
  min-width: 1rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dotsIcon:hover {
  color: rgba(135, 135, 139, 0.55);
}
