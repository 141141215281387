.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.42);
    z-index: 2000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box {
    background-color: white;
    height: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

}
.editorWrapper {
    display: flex;
    flex: 1;
    overflow: hidden;

}
.webView {
    height: 100%;
    max-width: 210mm !important;
}
