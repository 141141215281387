.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.filterButton {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b11873;
  color: white;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.filterButtonIcon {
  font-size: 13px;
}

.fields {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.input {
  min-width: 180px;
  max-height: 36px;
}

.input > :global(div) {
  padding: 0;
  max-height: 36px;
}

.inputSelect {
  margin: -26px 0 0;
}

.inputSelect > :global(div:first-child > span) {
  top: 8px;
}

.inputDate {
  margin: -36px 0 0;
}

.saveButton {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  padding: 8px 16px 8px 18px !important;
  text-transform: capitalize !important;
}

.saveButtonContainer {
  margin: 0 12px 0 auto !important;
}

.clearButtonContainer {
  margin: 0 0 0 8px;
}

.clearButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

.clearButtonCounter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 5px;
  border-radius: 4px;
}

.clearButtonIcon :global(path) {
  stroke: #b11873;
}

.selectCorporateUsers
  :global(form > div > div:first-of-type > div > div > div:first-of-type) {
  max-width: 180px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
