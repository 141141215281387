.departmentCardHeader {
    position: relative;
    display: flex;
    align-items: baseline;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #070f17;
    margin-bottom: 17px;
}
.departmentCardHeader p {
    margin-bottom: 0;
}
.departmentCardHeader::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #cdcfd1;
    bottom: -5px;
}
.seeAll {
    margin-left: 6px;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 1px 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #000;
}

.departmentCardContent {
    padding: 0px 12px 8px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    max-height: 200px;
    overflow-y: auto;
}

.departmentCardContent div {
    border-radius: 8px;
}

.departmentButton {
    display: flex;
    background-color: #CCF1F3; 
    color: #1E9DBD;
    padding: 5px 15px;
    border-radius: 4px;
    column-gap: 6px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
}

.departmentAddUser {
    display: flex;
}

.noUsers {
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #070f17;
}
.noUsers div {
    padding: 6px;
    margin-right: 10px;
    border-radius: 4px;
}
.noUsers div i {
    font-size: 20px;
}

.mainRow {
    display: flex;
    padding: 8px 16px;
    gap: 16px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}

.cardContainer {
    background-color: #FFFFFF;
    padding: 15px 20px;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
}

.companyContent {
    padding: 10px;
}

.companyTable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
}

.headerContainer {
    border-bottom: 1px solid rgba(7, 15, 23, 0.1);
}

.headerText {
    font-weight: 900;
    padding: 0 0 5px 10px;
}

.flowsContent {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.addDepartment {
    align-items: flex-start;
    padding-top: 8px;
}