.container {
  width: 100%;
  padding-bottom: 1rem;
}

.link {
  color: #070F17;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}

.copyright {
  color: #070F17;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 6px 0 0;
}
