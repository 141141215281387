.corporateAccount {
    background-color: #F3F3F4;
    height: 100%;
}

.createCompanyContainer{
    text-align: center;
}

.welcomeHeader{
    justify-content: center;
}
