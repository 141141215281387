.optionsContainer {
    max-height: 150px;
    overflow: auto;
    background-color: #f1f1f1;
    border-radius: 20px;
    padding: 0 16px;
    margin: 8px 16px 0 16px;
}
.modalContainer {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBox {
    background-color: white;
    height: 90vh;
    border-radius: 8px;
    overflow: auto;
}

.optionsShape {

}

.option {
    width: 100%;
    padding: 8px 0;
    border-bottom: solid 2px #ebebeb;
    display: flex;
    align-items: center;
}

.optionText {
    width: 100%;
    font-size: 11pt;
}
