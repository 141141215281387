.wrapper {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    background-color: #fff;
    border-bottom: 1px solid #E7E7E8;
}

.logo {
    position: relative;
    margin: 0 0 0 1px;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.tabContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.logoContainer img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.logoContainerDesktop {
    max-width: 160px;
    margin: -4px 0 0 26px;
}

.logoContainerMobile {
    display: none;
}

.headerContentContainer {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.assistanceContainer {
    width: 208px;
    margin: 0 4px 0 12px;
}

.assistanceButton {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
}

.assistanceTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
}

.mic {
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M8 0.666656C7.46957 0.666656 6.96086 0.87737 6.58579 1.25244C6.21071 1.62752 6 2.13622 6 2.66666V7.99999C6 8.53042 6.21071 9.03913 6.58579 9.4142C6.96086 9.78928 7.46957 9.99999 8 9.99999C8.53043 9.99999 9.03914 9.78928 9.41421 9.4142C9.78929 9.03913 10 8.53042 10 7.99999V2.66666C10 2.13622 9.78929 1.62752 9.41421 1.25244C9.03914 0.87737 8.53043 0.666656 8 0.666656V0.666656Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.6666 6.66666V7.99999C12.6666 9.23767 12.175 10.4247 11.2998 11.2998C10.4246 12.175 9.23766 12.6667 7.99998 12.6667C6.7623 12.6667 5.57532 12.175 4.70015 11.2998C3.82498 10.4247 3.33331 9.23767 3.33331 7.99999V6.66666' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 12.6667V15.3333' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.33331 15.3333H10.6666' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.searchContainer {
    display: flex;
    align-items: center;
    position: relative;
}

.serchIcon {
    background: #f3f3f4;
}

.search {
    width: 36px;
    height: 36px;
}

.searchButton {
    all: unset;
}

.searchInput {
    width: 100%;
    min-width: 148px;
    height: 36px;
    border-top-right-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0px;
    padding-left: 12px;
    padding-right: 45px;
    border: 1px solid #e7e7e8;
    border-right: none;
}

.searchLensImage {
    position: absolute;
    right: 16px;
    color: #909090;
}

.mainAndEditor {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}

.mainAndEditor .mainContent {
    justify-content: flex-start;
    padding-top: 20px;
}

.mainAndEditor > :global(div:last-of-type) {
    flex: 1;
}

.editorWrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
}

.editorWrapper > :global(.editor-container) {
    flex: 60%;
}

@media only screen and (max-width: 1320px) {
    .container {
        max-width: calc(100vw - 68px);
    }
    .logoContainerDesktop {
        display: none;
    }
    .logoContainerMobile {
        display: flex;
        width: 68px;
    }
    .mainAndEditor {
        margin-left: 68px;
    }
}

@media (max-width: 600px) {
    .assistanceContainer {
        width: 43px;
        min-width: 43px;
    }
    .assistanceTitle {
        display: none;
        width: 20px;
    }
}