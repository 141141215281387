.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.42);
    z-index: 1000;
    padding:20px
}

.box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 520px;
    width: 100%;
    min-width: 300px;
    min-height: 640px;
    max-height: 90vh;
    height: auto;
    background-color: white;
    border-radius: 8px;
}
