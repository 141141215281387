.divider {
    height: 1px;
    background-color:rgba(7, 15, 23, 0.1);
}

.employeeContainer {
    display: flex;
    column-gap: 40px;
    padding: 24px;
}

.employeeCard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 370px;
    height: fit-content;
    background-color: #FFF;
    border: 1px solid rgba(7, 15, 23, 0.1);
    border-radius: 8px;
    box-shadow: 0 0 10px 4px rgba(119, 121, 122, 0.2) ;
}

.employeeCardHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.employeeCardMain {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px 35px;
}

.employeeCardFooter {
    padding: 20px;
}

.employeeActivity {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.activityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.employeeFullName {
    font-weight: bold;
    font-size: 18px;
}

.employeePositionCompany {
    font-size: 14px;
    color: #83878B;
}

.activityValue {
    color: #1E9DBD;
    font-weight: bold;
    font-size: 24px;
}

.activityDescription {
    font-size: 14px;
}

.detailsHeader {
    color: rgb(177, 24, 115);
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
}

.detailsDescription {
    color: #83878B;
    font-size: 14px;
}

.detailsValue {
    margin: 0;
    font-weight: bold;
}

.employeeAvatar {
    margin-bottom: 20px;
}

.chartContainer {
    height: 400px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid rgba(7, 15, 23, 0.1);
    box-shadow: 0 0 10px 4px rgba(119, 121, 122, 0.2) ;
}

.employeeStats {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    flex: 1;
}

.employeeDocTempContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
}

.cardWrapper {
    padding: 20px;
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid rgba(7, 15, 23, 0.1);
    box-shadow: 0 0 10px 4px rgba(119, 121, 122, 0.2) ;
}

.cardHeader {
    font-size: 18px;
    font-weight: 900;
    border-bottom: 1px solid rgba(7, 15, 23, 0.1);
}

.listContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 10px 0;
}

.listRow {
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}


.listRow:hover {
    color: #B11873;
    transition: 300ms;
    border-left: 4px solid #B11873;
    padding-left: 5px;
}

.listStatus {
    display: flex;
    min-width: 144px;
    font-size: 0.875rem;
    text-align: center;
}

.listTitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}