.itemWrapper {
    padding: 4px;
    display: flex;
    /*background-color: rgba(255,255,255,.4);
    backdrop-filter: blur(8px);*/
    background-color: white;
    border-radius: 8px;
    border: 1px #1596C0 solid;
    overflow: hidden;
    box-sizing: border-box;

    -webkit-box-shadow: 0px 0px 24px -14px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 24px -14px rgba(0,0,0,1);
    box-shadow: 0px 0px 24px -14px rgba(0,0,0,1);
    user-select: none;
    cursor: default;
    transition-duration: .3s;
}

.animwrapper {
    box-sizing: border-box;
    padding: 0;
    transition-duration: .2s;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1596C0;
    font-size: 24px;
    padding: 4px;
    margin-right: 8px;
}

.overlay {
    position: fixed;
    bottom: 16px;
    left: 16px;
    z-index: 2000;
    display: flex;
}

.list {
    width: 240px;
    position: fixed;
    bottom: 48px;
    transition-duration: .2s;
    max-height: 85vh;
    overflow-y: auto;
}

.hideButton {
    position: fixed;
    bottom: 48px;
    left: 0;
    width: 24px;
    height: 100px;
    border: none;
    border-radius: 0 8px 8px 0;
    background-color: #1596C0;
    color: white;
    opacity: .8;
    transition-duration: .3s;
}

.hideButton:hover {
    opacity: 1;
}
.hideButton:focus {
    outline: none;
}


.itemTitle {
    font-weight: bold;
    font-size: 14px;
}
.itemHint {
    font-size: 12px;
}

.texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1
}

.chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1596C0;
    font-size: 16px;
    padding: 4px;
    margin-right: 8px;
    cursor: pointer;
}

@media only screen and (max-width: 1077px) {
    .hideButton {
        bottom:120px;
    }
}