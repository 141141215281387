.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 32px;
}

.button {
  appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 80px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: none;
  border-radius: 1.5rem;
  outline: none !important;
  user-select: none;
  transition-duration: 0.2s;
  -webkit-tap-highlight-color: transparent !important;
}

.button div,
.button span,
.button {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
}

.withShadow:hover {
  -webkit-box-shadow: inset 0px -1px 36px -14px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: inset 0px -1px 36px -14px rgba(0, 0, 0, 0.33);
  box-shadow: inset 0px -1px 36px -14px rgba(0, 0, 0, 0.33);
}
