.ReactTable {
    border: none !important;
}

.rt-table {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 8px;
}

.rt-tr {
    /*position: relative !important;*/
}

.rt-th {
    background-color: #F7F7F7 !important;
    padding: 7px 10px !important;
    font-weight: 700;
    color: #616161;
    font-size: 13px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}
.rt-th input {
    border-radius: 4px !important;
    border: 2px solid rgba(0,0,0,0.05) !important;
    transition-duration: .3s;
}
.rt-th input:focus {
    border: 2px solid #616161 !important;

}
.rt-thead {
    box-shadow: none !important;
}

.rt-td {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: white;*/
    height: 50px;
    /*height: 60px;*/
    /*max-height: 60px;*/
}
.rt-resizable-header-content {
    width: 100% !important;
    text-align: left;
}

.-pagination {
    display: flex !important;
    justify-content: center !important;
    border: none !important;
    box-shadow: none !important;
    height: 40px !important;
    padding-top: 10px !important;
    /*padding: 5px 0 !important;*/
}

.-center {
    flex-grow: 0 !important;
    margin: 0 7px;
}

.-next, .-previous {
    display: flex !important;
    align-items: center  !important;
    flex-grow: 0 !important;
    font-size: 25px;
    color: #554590;
    cursor: pointer;
}

.-pageinfo > input {
    text-align: center;
}

.-sort-desc {
}
